import PropTypes from "prop-types";

export const DAYS_IN_MONTH = [
    31, 28, 31, 30,
    31, 30, 31, 31, 
    30, 31, 30, 31
]

export const MONTH_START_DAY = (() => {
    let _d=0
    return DAYS_IN_MONTH.map(dim => {
        const tmp = _d
        _d += dim
        return tmp
    })
})()

export const MONTH_LABELS = [ 
    [ 'Jan', 'January'],
    [ 'Feb', 'February'],
    [ 'Mar', 'March'],
    [ 'Apr', 'April'],
    [ 'May', 'May'],
    [ 'Jun', 'June'],
    [ 'Jul', 'July'],
    [ 'Aug', 'August'],
    [ 'Sep', 'September'],
    [ 'Oct', 'October'],
    [ 'Nov', 'November'],
    [ 'Dec', 'December']
]

export const HOUR_LABELS = [ 
    "00:00", "01:00","02:00","03:00","04:00","05:00",
    "06:00", "07:00","08:00","09:00","10:00","11:00",
    "12:00", "13:00","14:00", "15:00","16:00","17:00",
    "18:00", "19:00","20:00","21:00","22:00","23:00","00:00"
]

export const HOURS_IN_YEAR = 24*365 ;

export const EnergyCommunityType = () => PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    readings: (props, propName, componentName) => {
        const _val = props[propName]
        if(_val) {
            if(!Array.isArray(_val) || _val.length!==HOURS_IN_YEAR) {
                return new Error(`Property "${propName}" should be an array of ${HOURS_IN_YEAR} numbers - got ${_val.length}`)
            }
            for(let i=0; i<HOURS_IN_YEAR; ++i) {
                if(typeof(_val[i])!=='number') {
                    return new Error(`Property "${propName}" should be an array of ${HOURS_IN_YEAR} numbers. Entry [${i}] is "${typeof _val[i]}"`)
                }
            } 
        }
    }
})