import Session from "services/session"
import Request from "services/http"

const empty = /^\s*$/

export class AuthenticatedUser {
    constructor(_u) {
      this.id = _u.id;
      this.firstname = _u.firstname;
      this.lastname = _u.lastname;
      this.email = _u.email;
      this.organisation = _u.organisation;
      this.organisationId = _u.organisation && _u.organisation.id
      this.clusterId = _u.clusterId
      this.clusterName = _u.clusterName
      this.roles = _u.roles || [];
      this.isUser = this.roles.includes('user')
      this.isAdmin = this.roles.includes('admin')
      this.isOrgAdmin = this.roles.includes('cadmin')
      this.isClusterOwner = this.roles.includes('cluster_owner')
      this.isNetworkAdmin = this.roles.includes('cluster_owner')
    }
  
    static create(u) {
        let _u = u
      if (typeof _u !== 'undefined' && _u !== null) {
        try {
          if (typeof _u === "string" && !_u.match(empty)) {
            _u = JSON.parse(_u);
          }
          if (
            typeof _u.id !== "undefined" &&
            typeof _u.email !== "undefined" &&
            typeof _u.lastname !== "undefined" &&
            typeof _u.organisation !== "undefined" &&
            typeof _u.organisation.id !== "undefined"
          ) {
            return new AuthenticatedUser(_u);
          }
        } catch (err) {
          console.error("AuthenticatedUser::create() : ", err);
        }
      }
      return null;
    }
}

function AuthService() {
    const $this = {
        user: null
    }

    const __request = Request("user")

    function clearSession() {
        $this._user=null ;
        Session.clear();
        // localStorage.clear('token')
        // window.location.href = "/authenticate/sign-in";
        console.log('Logged out')
        return null;
    }
    
    function createSession(_user) {
        if (_user !== null) {
            Session.set("_user", JSON.stringify(_user));
            console.log(`createSession() : Created session for ${_user.email}`)
            // window.location.href = "/dashboard";
            $this.user = _user;
            return _user;
        }
        return clearSession();
    }

    $this.login = async (email, password) => {
        try {
            if($this.user) {
                // Check if logged in already
                const _u = await $this.me()
                if(_u) return _u
            }
            const __user = await __request.put("authenticate", {email, password})
            return createSession(AuthenticatedUser.create(__user))
        }
        catch(err) {
            console.error("Auth error : ", err)
            return clearSession()
        }
    }

    $this.me = async (handle401=true) => {
        // if 'handle401' is true, the Request object will handle the error 
        // and attempt to redirect. This can cause login loops
        try {
            const __user = await __request.call({path: "me", method: "get", handle401})
            return createSession(AuthenticatedUser.create(__user))
        }
        catch(err) {
            return clearSession()
        }
    }


    $this.logout = async () => {
        try {
            await __request.put('logout')
        }
        catch(err) {
            console.log(err)
        }
        clearSession()
    }
    return $this
}

export default AuthService()
  