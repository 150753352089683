import { useState, useContext, useEffect, useMemo } from "react"

import { EnergyCommunityType, MONTH_LABELS, DAYS_IN_MONTH} from "layouts/energy-community/types";

import Session from "services/session"
import Grid from "@mui/material/Grid";

import MDTypography from "components/MDTypography";

import Service from "services/energy-community"
import Loading from "components/loading"
import ECPlotContext, {EMPTY_READINGS} from "../plot-context";

import PlotYear from "./PlotYear"
import PlotMonth from "./PlotMonth"
import PlotDay from "./PlotDay"

const EC_PLOT_SELECTED_MONTH = 'energy.community.plot.month.selected'
const EC_PLOT_SELECTED_DAY = 'energy.community.plot.day.selected'

function parseReadings(readings) {
    if(!Array.isArray(readings) || readings.length===0) return null

    // Aggreate each day
    const dayData = new Array(365)
    for(let d=0; d<365; ++d) {
        const __hourly = readings.slice(d*24, (d+1)*24)
        dayData[d] = {
            total: __hourly.reduce((t,r) => t+r,0),
            data: __hourly
        }
    }
    return dayData
}

function EnergyCommunityPlot() {
    const {community} = useContext(ECPlotContext)

    const [month, setMonth] = useState(Session.get(EC_PLOT_SELECTED_MONTH, 0))
    const [day, setDay] = useState(Session.get(EC_PLOT_SELECTED_DAY, 0))
    const [selectedDay, setSelectedDay] = useState("")

    useEffect(() => {
        let _d = day
        const daysInMonth=DAYS_IN_MONTH[month]
        if(_d>daysInMonth-1) {
            _d=daysInMonth-1
        }

        // Determine whether day is "st", "nd", or "th"
        let dayLabel='th'
        const dom = _d+1
        if(dom<9 || dom>19) {
            switch(dom%10) {
                case 1:
                    dayLabel = 'st'
                    break
                case 2:
                    dayLabel = 'nd'
                    break
                case 3:
                    dayLabel = 'rd'
                    break
                default:
                    dayLabel = 'th'
                    break
            }
        }
        setSelectedDay(`${MONTH_LABELS[month][1]} ${dom}${dayLabel}`)

    }, [month, day])

    return community?(
        <Grid container spacing={3}>
            {community && (<>
            <Grid item xs={12} md={6} lg={6}>
                <PlotYear setMonth={setMonth}
                    series={["load", "ev", "heatpump", "solar", "batteryDischarge", "spill"]}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <PlotMonth month={month} setDay={setDay}
                    series={["load", "ev", "heatpump", "solar", "batteryDischarge", "spill"]}/>
            </Grid>
            <Grid item xs={12}>
                <MDTypography variant="h4">{selectedDay}</MDTypography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <PlotDay month={month} day={day} 
                    title="Load" subtitle={`Breakdown of load for ${selectedDay}`}
                    series={["load", "ev", "heatpump"]}/>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <PlotDay month={month} day={day} 
                    title="Renewables" subtitle={`Breakdown of renewables for ${selectedDay}`}
                    series={["batteryCharge", "solar", "batteryDischarge", "spill"]}/>
            </Grid>
            </>)}
        </Grid>            
    ):null
}

export default EnergyCommunityPlot