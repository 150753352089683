import { useState, useRef, useContext, useMemo } from "react"
import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import { MONTH_LABELS, DAYS_IN_MONTH, MONTH_START_DAY} from "layouts/energy-community/types";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import {createPlot} from "layouts/energy-community/Plots/EnergyCommunityPlot/configs";
import Card from "@mui/material/Card";
import ECPlotContext from "../plot-context";
import FullScreenPlot from "./FullScreenPlot"

export default function PlotYear({setMonth, series}) {
    const { getSeries } = useContext(ECPlotContext)
    const [plotData, setPlotData] = useState({})
    const _ref = useRef() 

    const selectMonth = (event) => {
        console.log('selectMonth : ', _ref.current)
        const [clicked=null] = getElementsAtEvent(_ref.current, event);
        if(clicked!==null) {
            setMonth(clicked.index)
        }
    }

    const plot = useMemo(() => {
        const labels = MONTH_LABELS.map(([l]) => l)

        // Sum the totals for all days in a month
        const __total = _series => {
            const _year = Array(12)
            for(let i=0; i<12;++i) {
                const start = MONTH_START_DAY[i]
                const end = start+DAYS_IN_MONTH[i]
                const monthSeries = _series.slice(start, end)
                _year[i] = monthSeries
                    .reduce((t, {total: daily}) => t+daily, 0)
            }
            return _year
        }

        const __data = createPlot({
            labels, 
            datasets: series.map( s => {
                const __seriesData = getSeries(s)
                return [s, __total(__seriesData)]
            }),
            summary: ["load", "renewable"],
            group: true
        })
        setPlotData(__data)
        return (
            <Bar ref={_ref} onClick={selectMonth} options={__data.options} data={__data.config}/>
        )
    }, [])
    

    return (<Card sx={{ height: "100%" }}>
        <MDBox padding="0.2rem">
            <MDBox
                variant="gradient"
                sx= {{
                    bgcolor: grey[700]
                }}
                borderRadius="lg"
                py={2}
                pr={0.5}>
                    {plot}
            </MDBox>
            <FullScreenPlot title="Monthly" plot={plot} data={plotData} onClick={setMonth}/>
            <MDBox>
                <MDTypography variant="h6" textTransform="capitalize">Monthly</MDTypography>
                <MDTypography component="div" variant="button" color="text" fontWeight="light">
                    Energy data by month
                </MDTypography>
            </MDBox>
        </MDBox>
    </Card>)
}
PlotYear.propTypes = {
    setMonth: PropTypes.func,
    series: PropTypes.arrayOf(PropTypes.string),
}
PlotYear.defaultProps = {
    setMonth: () => {},
    series: ['grid', 'solar', 'spill'],
}