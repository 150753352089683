import { useState, useEffect, useMemo, useCallback, useContext } from "react"

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EnergyCommunityContext from 'layouts/energy-community/components/ec-context'


const TreeNode = ({_id: value, name: label, type}) => {
    const n = {value, label}
    switch(type) {
        case 'branch':
            n.icon=(<FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="code-branch" />)
            break
        case 'transformer':
            n.icon=(<FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="bolt" />)
            break
        case 'dg':
            n.icon=(<FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="plug-circle-bolt" />)
            break
        default: 
            break;
    }
    return n
}

const RICONS={
    check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon="check-square" />,
    uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={['fas', 'square']} />,
    halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon="check-square" />,
    expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon="chevron-right" />,
    expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon="chevron-down" />,
    expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
    collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
    parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
    parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
    leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="file" />
}

export default function CommunityTree({tree, selectedSites, events}) {
    const { model } = useContext(EnergyCommunityContext)
    // const { siteManager } = useContext(EnergyCommunityContext)
    const [nodes, setNodes] = useState(null)
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const rootNode = useMemo(() => ({
        _id: 1,
        name: model.name
    }), [model.name])

    useEffect(() => {
        function mapTree(t) {
            const n = TreeNode(t)
            const { _children=[]} = t
            if(_children.length>0) {
                n.children = _children.map(c => c._children && c._children.length>0?mapTree(c):TreeNode(c))
            }
            return n
        }

        let __nodes=null
        if(tree && tree.length>0) {
            __nodes=[mapTree({
                ...rootNode,
                _children: tree
            })]
            setNodes(__nodes)
        }
        // setNodes(null)

        return () => {
            setNodes(null)
        }
    }, [tree, rootNode])

    useEffect(() => {
        setChecked(selectedSites)
    }, [selectedSites])

    useEffect(() => {
        setChecked(model.sites)
    }, [model.sites])

    const handleChecked = useCallback((__chkd) => {
        
        const diff=(a1, a2) => {
            const __diff = []
            for(const __id of a1) {
                if(a2.indexOf(__id)===-1) __diff.push(__id)
            }
            return __diff
        }

        if(checked.length>__chkd.length) {
            // Deleted
            const diffed = diff(checked, __chkd)
            events.delete(diffed)
        }
        else {
            // Added
            const diffed = diff(__chkd, checked)
            events.add(diffed)
            
        }
        model.sites = __chkd
        setChecked(__chkd)
    }, [checked])

    

    return (<div>
        <Grid container>
            <Grid item>
                <MDTypography variant="h4">Sites</MDTypography>
                <MDTypography varient="subtitle2">Community - {model.name}</MDTypography>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item>
                <MDBox>
                    {
                        nodes?(<CheckboxTree
                            icons={RICONS}
                            checked={checked}
                            iconsClass="fa6"
                            expanded={expanded}
                            nodes={nodes}
                            onCheck={handleChecked}
                            onExpand={setExpanded}/>
                        ):(<>
                            <MDTypography variant="subtitle2">No sites found</MDTypography>
                    <MDTypography variant="body2">
                        Click branches and/or transformers on the map to add them 
                        to the community
                    </MDTypography>
                        </>)
                    }
                </MDBox>
            </Grid>
        </Grid>
    </div>)
}


const TREE_NODE=PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    _children: PropTypes.arrayOf(PropTypes.shape({}))
})

const NOOP = () => {}

CommunityTree.defaultProps = {
    tree: null,
    selectedSites: [],
    events: {
        add: NOOP,
        delete: NOOP,
        details: NOOP,
    }
}

CommunityTree.propTypes = {
    tree: PropTypes.arrayOf(TREE_NODE),
    selectedSites: PropTypes.arrayOf(PropTypes.string),
    events: PropTypes.shape({
        add: PropTypes.func,
        delete: PropTypes.func,
        details: PropTypes.func
    })
}