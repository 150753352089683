import { useState, useMemo, useEffect, useContext } from "react"

import {useMap, Popup} from 'react-leaflet'

import PropTypes from "prop-types";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import R from "leaflet-responsive-popup"
import "leaflet-responsive-popup/leaflet.responsive.popup.css";

import {findTopBranch} from "services/meter-service"

import EnergyCommunityContext from 'layouts/energy-community/components/ec-context'

// Generate a random 8 character hex string to append
const POPUP_DIV_ID=`node-popup-${Math.floor(Math.random() * 256**4).toString(16)}`



const NOOP = () => {}
const DEFAULT_HANDLERS={
    add: NOOP,
    delete: NOOP,
    details: NOOP,
}

export default function NodeSelectPopup({selected, branches, events, onClose, present, editControls, children}) {
    const { model } = useContext(EnergyCommunityContext)

    const map = useMap()
    const [node, setNode]=useState(null)
    const [marker, setMarker] = useState(null)
    const [action, setAction] = useState(null)

    const close = () => {
        setNode(null)
        onClose()
    }

    useEffect(() => {
        if(!selected) return

        const { marker: m, node: n } = selected
        if(!m) {
            console.warn("Marker not set for popup")
            return
        }
        if(!n) {
            console.warn("Node not selected for popup")
            return
        }

        setMarker(m)
        setNode(n)

        return () => {
            setMarker(null)
            setNode(null)
        }
    }, [selected])

    useEffect(() => {
        if(!node) return
        const __branch = findTopBranch(node)
        if(!__branch) {
            console.warn(`No branch for node`)
        }
    }, [node])

    const eventHandlers = useMemo(() => ({...DEFAULT_HANDLERS, ...events}), [events])

    const popup = useMemo(() => {
        if(!node) return null

        const __h = (e) => {
            const __fn=eventHandlers[e]
            if(!__fn) {
                throw new Error(`Invalid action "${e}"`)
            }
            __fn(node)
            close()
        }

        return (
            <Popup position={node.location}
                eventHandlers={{
                    remove: () => close()
                }}>
                <div>
                    <div>
                        <strong>{node.name}</strong>
                    </div> 
                    <hr sx={{marginTop: "0.5em", marginBottom: "0.5em"}}/>
                    {children}
                    {editControls ? (<MDBox spacing={1} mt={1.5} display="flex" justifyContent="flex-end">
                        {/* <MDButton 
                            size="small" 
                            sx={{padding: "0.25em"}}
                            variant="outlined" 
                            onClick={() => __h('details')}
                            color="secondary">Details</MDButton> */}
                        {present?(<MDButton 
                            size="small" 
                            pl={1.5}
                            variant="contained" 
                            onClick={() => __h('delete')}
                            color="primary">Delete</MDButton>):
                        (<MDButton 
                            size="small" 
                            pl={1.5}
                            variant="contained" 
                            onClick={() => __h('add')}
                            color="primary">Add</MDButton>)}
                    </MDBox>):null}
                </div>            
            </Popup>
        )
    }, [node, eventHandlers, children])

    return popup
}



NodeSelectPopup.defaultProps = {
    selected: null,
    events: {...DEFAULT_HANDLERS},
    onClose: NOOP,
    present: false,
    editControls: true,
    children: false
}

NodeSelectPopup.propTypes = {
    selected: PropTypes.shape({
        node: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            location: PropTypes.arrayOf(PropTypes.number).isRequired
        }),
        marker: PropTypes.shape({})
    }),
    events: PropTypes.shape({
        add: PropTypes.func,
        delete: PropTypes.func,
        details: PropTypes.func
    }),
    onClose: PropTypes.func,
    present: PropTypes.bool,
    editControls: PropTypes.bool,
    children: PropTypes.node
}