import { PropTypes } from "prop-types"
import LoopIcon from "@mui/icons-material/Loop"
import { Typography } from "@mui/material"

export default function Loading({text, color, fontWeight}) {
    return (<Typography variant="h3" color="#888" fontWeight="300"><LoopIcon
        sx={{
            animation: "spin 1s linear infinite",
            "@keyframes spin": {
                "0%" : {
                    transform: "rotate(360deg)"
                },
                "100%" : {
                    transform: "rotate(0deg)"
                }
            }
        }}
    /> {text}</Typography>)
}

Loading.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    fontWeight: PropTypes.number
}

Loading.defaultProps = {
    text: "Loading....",
    color: "#888",
    fontWeight: 300
}