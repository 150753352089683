import { useEffect, useState, useRef, useMemo } from "react"

import PropTypes from "prop-types";

import { Fade, Backdrop, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"

const NOOP = ()=> {}

export default function LoadingBackdrop({open, delay, timeout, onTimeout, timeoutMessage, onClose}) {
    const [loading, setLoading] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    const delayRef = useRef()
    const timeoutRef = useRef()


    const handleClose = () => {
        setLoading(false)
        onTimeout()
    }

    useEffect(() => {
        if(delayRef.current) {
            clearTimeout(delayRef.current)
        }
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        if(open) {
            delayRef.current = setTimeout(() => {
                setLoading(true)
            }, delay)
            if(timeout>0) {
                timeoutRef.current = setTimeout(() => {
                    if(timeoutMessage!==null) {
                        setAlertVisible(true)
                    }
                    else {
                        handleClose()
                    }
                }, timeout)
            }
        }
    }, [open])

    const timeoutAlert = useMemo(() => {
        if(timeoutMessage===null || !alertVisible) return null

        const closeAlert = () => {
            setAlertVisible(false)
            handleClose()
        }

        return (<Dialog
                open={alertVisible}
                onClose={closeAlert}
                aria-labelledby="alert-dialog-title"    
                aria-describedby="alert-dialog-description/">
            <DialogTitle id="alert-dialog-title">Timed Out</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {timeoutMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeAlert}>OK</Button>
            </DialogActions>
        </Dialog>)
    }, [timeoutMessage, alertVisible])

    return (<Fade in={loading}
            style={{
                transitionDelay: open?`100ms`:'0ms'
            }}
            unmountOnExit>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}>
            {alertVisible?timeoutAlert:<>
                <CircularProgress/>
                <Typography sx={{ml: 1}} color="primary">Working....</Typography>
            </>}
        </Backdrop>
    </Fade>)
}

LoadingBackdrop.propTypes = {
    open: PropTypes.bool,
    delay: PropTypes.number,
    timeout: PropTypes.number,
    timeoutMessage: PropTypes.string,
    onTimeout: PropTypes.func,
    onClose: PropTypes.func
}
LoadingBackdrop.defaultProps = {
    open: false,
    delay: 800,
    timeout: 0,
    timeoutMessage: null,
    onTimeout: NOOP,
    onClose: NOOP
}