/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {scaleWattHours} from "services/scale-series"
import { red, blue, green, orange, yellow, blueGrey } from "@mui/material/colors"

const BASE_CONFIG = {
    tension: 0.4,
    borderWidth: 0,
    borderRadius: 4,
    borderSkipped: false,
    backgroundColor: red[500],
    maxBarThickness: 16,
}

const SUMMARY_TYPES = {
    "load": {
        label: "Tot. Load"
    },
    "renewable": {
        label: "Renewables"
    }
}

const CONFIGS = {
    grid: {
        label: 'Grid',
        backgroundColor: 'rgb(75, 192, 192)',
    },
    solar: {
        label: 'Solar',
        __type: 'renewable',
        backgroundColor: 'rgb(255, 99, 132)',
    },
    spill: {
        label: 'Spill',
        __type: "renewable",
        backgroundColor: blueGrey[200],
    },
    load: {
        label: 'Load',
        __type: "load",
        backgroundColor: blue[500]
    },
    ev: {
        label: 'EV',
        __type: "load",
        backgroundColor: green[500]
    },
    heatpump: {
        label: 'Heat Pump',
        __type: "load",
        backgroundColor: yellow[500]
    },
    batteryCharge: {
        label: 'Battery Charge',
        __type: 'renewable',
        backgroundColor: green[500]
    },
    batteryDischarge: {
        label: 'Battery Discharge',
        __type: 'renewable',
        backgroundColor: orange[500]
    }
}

export function getType(s) {
    const _c = CONFIGS[s]
    if(!_c) {
        throw new Error(`No such series "${s}"`)
    }
    return _c.__type?_c.__type:'default'
}

export function createPlot({labels=[], datasets=[], summary=[], group=false} = {}) {
    // Create a scaler - aggregate all positive values
    // console.log('Datasets : ', datasets)
    const toScale = Array(labels.length).fill(0)
    for(const [,_ds] of datasets) {
        for(let i=0; i<toScale.length; ++i) {
            const v = _ds[i]
            if(v>0) toScale[i] += v
        }
    }
    const __scaler = scaleWattHours(toScale)
    const _datasets = datasets.map(([type, data]) => {
        const _stack = group?{ 
            stack: getType(type)
        }:{}
        // console.log('Stack : ', _stack, CONFIGS[type])
        return {
            ...BASE_CONFIG,
            ...CONFIGS[type],
            ..._stack,
            data: __scaler.scale(data)
        }
    })
    // console.log('Datasets : ', _datasets)
    const __loadSeries = []
    const __spillSeries = [] 
    for(let i=0; i<datasets.length; ++i) {
        const s = datasets[i][0]
        if(s==="grid" || s==="solar") {
            __loadSeries.push(i)
        }
        else if(s==="spill") {
            __spillSeries.push(i)
        }
    }
    // console.log('Summary : ', summary, labels)
    const __summaries = labels.map((l, i) => {
        const __summary = []
        for(const sType of summary) {
            const __type = SUMMARY_TYPES[sType]
            if(__type) {
                const {label: sLabel, absolute: isAbsolute=true, temporal: isTemporal=true} = __type
                for(let iDS=0; iDS<datasets.length; ++iDS) {
                    const ds = datasets[iDS]
                }
                const _total = datasets.reduce((t, [ds], iDS) => {
                    // console.log(`--Summary[${ds}]`)
                    if(CONFIGS[ds].__type === sType) {
                        return _datasets[iDS].data[i] + (t===null?0:t)
                    }
                    return t
                }, null)
                if(_total!==null) {
                    const __t = __scaler.toPrecision(isAbsolute?Math.abs(_total):_total)
                    __summary.push(`${sLabel} ${__t}${isTemporal?__scaler.unitsTemporal:__scaler.unitsEnergy}`)
                }
            }
        }
        return __summary.join(', ')
    })
    // console.log('Summaries : ', __summaries)
    const __seriesMap = new Map()
    for(let i=0; i<labels.length; ++i) {
        let __load = 0
        for(const __li of __loadSeries) {
            __load+=_datasets[__li].data[i]
        }
        let __spill = 0
        for(const __si of __spillSeries) {
            __spill+=_datasets[__si].data[i]
        }
        __seriesMap.set(labels[i], { 
            load: __scaler.toPrecision(__load), 
            spill: __scaler.toPrecision(__spill)
        })

    }


    const yLabel = `Energy (${__scaler.unitsTemporal})`
    return {
        scaler: __scaler,
        config: {
            labels,
            datasets: _datasets
        },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    display: true, 
                    labels: {
                        color: "rgba(255, 255, 255)",
                    }
                },
                tooltip: {
                    callbacks: {
                        footer: (ttItems) => {
                            if(ttItems.length>0) {
                                const {dataIndex=null} = ttItems[0]
                                if(dataIndex!==null) {
                                    return __summaries[dataIndex]
                                }
                            }
                            return ""
                        }
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: "rgba(255, 255, 255, .2)",
                      },
                      ticks: {
                        display: true,
                        color: "#f8f9fa",
                        padding: 10,
                      },
                    stacked: true
                },
                y: {
                    title: {
                        display: yLabel.length>0,
                        text: yLabel,
                        color: "rgba(255, 255, 255)",
                    },
                    grid: {
                      drawBorder: false,
                      display: true,
                      drawOnChartArea: true,
                      drawTicks: false,
                      borderDash: [5, 5],
                      color: "rgba(255, 255, 255, .2)",
                    },
                    ticks: {
                      suggestedMin: 0,
                      suggestedMax: 500,
                      beginAtZero: true,
                      padding: 10,
                      font: {
                        size: 14,
                        weight: 300,
                        family: "Roboto",
                        style: "normal",
                        lineHeight: 2,
                      },
                      color: "#fff",
                    },
                    stacked: true
                }
            }
        }
    }
}

function BarChartConfig(config, datasets) {
    const {xLabel="", yLabel="", categories} = config
    return {
      data: {
        labels: categories,
        datasets: [
          {
            label: datasets.label,
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            data: datasets.data,
            maxBarThickness: 16,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            title: {
                display: yLabel.length>0,
                text: yLabel,
                color: "rgba(255, 255, 255)",
            },
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 500,
              beginAtZero: true,
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
              color: "#fff",
            },
          },
          x: {
            title: {
                display: xLabel.length>0,
                text: xLabel,
                color: "rgba(255, 255, 255)",
            },
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    };
  }
  
function LineChartConfig(config, datasets) {
    const {xLabel="", yLabel="", categories} = config

    return {
      data: {
        labels: categories,
        datasets: [
          {
            label: datasets.label,
            tension: 0,
            pointRadius: 2,
            pointBorderColor: "transparent",
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            borderColor: "rgba(255, 255, 255, .8)",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            data: datasets.data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            title: {
                display: yLabel.length>0,
                text: yLabel,
                color: "rgba(255, 255, 255)",
            },
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            title: {
                display: xLabel.length>0,
                text: xLabel,
                color: "rgba(255, 255, 255)",
            },
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    };
  }

  export {BarChartConfig, LineChartConfig}

  export default BarChartConfig;
  