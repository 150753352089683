import { useState, useEffect, useCallback, useMemo } from 'react'

import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import MDButton from "components/MDButton";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const NOOP = () => {}

const TRUE_FUNC = () => true

const strip = (s="") => s.replace(/(^\W+|\W+$)/g, '').replace(/\W+/g, '-').toLowerCase()

export default function ActionableButton({prompt, actions, children, check, ...btnParams}) {
    const [open, setOpen] = useState(false)
    const [label, setLabel] = useState('')
    
    useEffect(() => {
        if(actions===null) return 

        setLabel(strip(prompt))

        return () => {
            setOpen(false)
        }
    }, [])

    const handleClick=useCallback(() => {
        if(check()) {
            setOpen(true)
        }
        else {
            // Don't need to confirm
            for(const a of actions) {
                if(a.default===true) {
                    const {action=NOOP} = a
                    action()
                    return 
                }
            }
            console.warn('No default action falling back to dialog')
            setOpen(true)
        }
    }, [actions])

    const confirmationDialog = useMemo(() => (<Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={`dialog-${label}-title`}>

        <DialogTitle id={`dialog-${label}-title`}>{prompt}</DialogTitle>
        <DialogActions>
            {actions.map(({label: btnLabel,
                icon,
                variant="outlined",
                color='primary',
                action=NOOP
            }) => (<MDButton 
                key={`btn-${label}-${strip(btnLabel)}`}
                variant={variant} 
                size="small" 
                onClick={() => {
                    setOpen(false)
                    action()
                }} 
                color={color} 
                startIcon={icon}>
                    {btnLabel}
            </MDButton>
            ))}
        </DialogActions>
    </Dialog>), [open, prompt, actions, setOpen])

    return (<><MDButton 
        onClick={handleClick}
        {...btnParams}>
            {children}
    </MDButton> 
    {confirmationDialog}
    </>)
}

ActionableButton.propTypes = {
    prompt: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.node,
        variant: PropTypes.string,
        color: PropTypes.string,
        action: PropTypes.func
    })).isRequired,
    children: PropTypes.node,
    check: PropTypes.func
}

ActionableButton.defaultProps = {
    children: "",
    check: TRUE_FUNC
}

export function YesNoButton({prompt,children, onNo, onYes, check, ...btnParams}) {
    return <ActionableButton
        prompt={prompt}
        check={check}
        actions={[{
            label: "No",
            icon: (<CloseIcon/>),
            variant: 'outlined',
            color: 'secondary',
            action: onNo
        },
        {
            label: "Yes",
            icon: (<CheckIcon/>),
            variant: 'contained',
            color: 'primary',
            default: true,
            action: onYes
        }]} {...btnParams}>{children}</ActionableButton>
}

YesNoButton.propTypes = {
    prompt: PropTypes.string.isRequired,
    children: PropTypes.node,
    onYes: PropTypes.func,
    onNo: PropTypes.func,
    check: PropTypes.func
}

YesNoButton.defaultProps = {
    children: "",
    onYes: NOOP,
    onNo: NOOP,
    check: TRUE_FUNC
}
