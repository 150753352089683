// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-marker-icon {
    width: 0;
    height: 0;
    border: 0;
    padding: 0;
}

.map-icon.red {
    color: red;
}

.map-icon.blue {
    color: blue;
}

.map-icon.green {
    color: green;
}

.map-icon .map-icon-bg {
    width: 14px;
    height: 14px;
    margin-top: 10px;
    margin-left: 6px;
    background-color: white;
}

.map-icon svg {
    float: left;
    margin-top: -23px;
}

.rct-icons-fa6 .rct-checkbox svg.rct-icon-uncheck {
    color: lightgrey;

}

.rct-icons-fa6 .rct-checkbox svg.rct-icon-half-check {
    color: grey;

}`, "",{"version":3,"sources":["webpack://./src/layouts/components/MapView/map-icons.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;IACT,SAAS;IACT,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;;AAEpB;;AAEA;IACI,WAAW;;AAEf","sourcesContent":[".leaflet-marker-icon {\n    width: 0;\n    height: 0;\n    border: 0;\n    padding: 0;\n}\n\n.map-icon.red {\n    color: red;\n}\n\n.map-icon.blue {\n    color: blue;\n}\n\n.map-icon.green {\n    color: green;\n}\n\n.map-icon .map-icon-bg {\n    width: 14px;\n    height: 14px;\n    margin-top: 10px;\n    margin-left: 6px;\n    background-color: white;\n}\n\n.map-icon svg {\n    float: left;\n    margin-top: -23px;\n}\n\n.rct-icons-fa6 .rct-checkbox svg.rct-icon-uncheck {\n    color: lightgrey;\n\n}\n\n.rct-icons-fa6 .rct-checkbox svg.rct-icon-half-check {\n    color: grey;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
