import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faSquare, faChevronRight, faChevronDown,
    faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile,
    faCodeBranch, faBolt, faPlugCircleBolt, faLocationDot, faMapLocation
} from '@fortawesome/free-solid-svg-icons'

library.add(faCheckSquare, faSquare, faChevronRight, faChevronDown,
    faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile,
    faCodeBranch, faBolt, faPlugCircleBolt, faLocationDot, faMapLocation)


function FontInit() {
    return {
        init: async () => console.log('Initialised fonts')
    }
}

export default FontInit()