import { useState, useRef, useContext, useMemo } from "react"
import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import { MONTH_LABELS, DAYS_IN_MONTH, MONTH_START_DAY} from "layouts/energy-community/types";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import {createPlot} from "layouts/energy-community/Plots/EnergyCommunityPlot/configs";
import Card from "@mui/material/Card";
import ECPlotContext from "../plot-context";
import FullScreenPlot from "./FullScreenPlot"

const __total = _series => _series.map(({total, series}) => total)

export default function PlotMonth({month, setDay, series}) {
    const { getSeries } = useContext(ECPlotContext)
    const [plotData, setPlotData] = useState({})
    const _ref = useRef() 

    const selectDay = (event) => {
        const [clicked=null] = getElementsAtEvent(_ref.current, event);
        if(clicked!==null) {
            setDay(clicked.index)
        }
    }

    const monthName = MONTH_LABELS[month][1]

    const plot = useMemo(() => {
        // Aggregate the data for the days of the month
        const daysInMonth=DAYS_IN_MONTH[month]
        const start = MONTH_START_DAY[month]
        const end = start + daysInMonth
        const labels = new Array(daysInMonth)
        for(let d=0;d<labels.length;++d) {
            labels[d] = (d+1).toString().padStart(2, '0')
        }
        const __data = createPlot({
            labels, 
            datasets: series.map( s => {
                const __seriesData = getSeries(s)
                return [s, __total(__seriesData.slice(start, end))]
            }),
            // datasets: [
            //     ['grid', __total(grid.slice(start, end))],
            //     ['solar', __total(solar.slice(start, end))],
            //     ['spill', __total(spill.slice(start, end))]
            // ],
            summary: ["load", "renewable"],
            group: true
        })
        setPlotData(__data)
        return (
            <Bar ref={_ref} onClick={selectDay} options={__data.options} data={__data.config}/>
        )
    }, [month])
    
    return (<Card sx={{ height: "100%" }}>
        <MDBox padding="0.2rem">
            <MDBox
                variant="gradient"
                sx= {{
                    bgcolor: grey[700]
                }}
                borderRadius="lg"
                // coloredShadow="success"
                py={2}
                pr={0.5}
                // mt={-5}
                // height="20rem"
                >
                    {plot}
            </MDBox>
            <FullScreenPlot title={monthName} plot={plot} data={plotData} onClick={setDay}/>
            <MDBox>
                <MDTypography variant="h6" textTransform="capitalize">{monthName}</MDTypography>
                <MDTypography component="div" variant="button" color="text" fontWeight="light">
                    Daily energy data for {monthName}
                </MDTypography>
            </MDBox>
        </MDBox>
    </Card>)
}
PlotMonth.propTypes = {
    month: PropTypes.number.isRequired,
    setDay: PropTypes.func,
    series: PropTypes.arrayOf(PropTypes.string)
}
PlotMonth.defaultProps = {
    setDay: () => {},
    series: ['grid', 'solar', 'spill']
}
