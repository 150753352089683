import {createContext} from "react"
import {HOURS_IN_YEAR, MONTH_START_DAY} from '../types'

export const EMPTY_READINGS = Array(HOURS_IN_YEAR).fill(0)

function parseReadings(readings) {
    // Aggreate each day
    const dayData = new Array(365)
    for(let d=0; d<365; ++d) {
        const __hourly = readings.slice(d*24, (d+1)*24)
        dayData[d] = {
            total: __hourly.reduce((t,r) => t+r,0),
            data: __hourly
        }
    }
    return dayData
}

const BATTERY_TEST = [
    0,0,0,0,0,0,
    0,0,0,20,20,20,
    20,20,20,-20,-20,-20
    -20,-20,-20,0,0,0
]
const EV_TEST = [
    20,20,20,20,20,20,
    0,0,0,0,0,0,
    0,0,20,20,0,0,
    0,0,50,50,50,50,
]
const HP_TEST = [
    40,40,40,40,40,40,
    40,40,40,40,40,40,
    0,0,0,0,0,0,
    0,0,0,0,0,0
]

const testFill = (testData) => {
    const a = new Array(HOURS_IN_YEAR)
    for(let day=0;day<365;++day) {
        for(let hour=0;hour<24;++hour) {
            a[(day*24)+hour] = testData[hour]
        }
    }
    return a
}
export function Context(community, setCommunity) {
    const { load, solar, battery, ev, heatpump} = community

    const _load = Array.isArray(load) && load.length!==0?load:EMPTY_READINGS
    const _solar = Array.isArray(solar) && solar.length!==0?solar:EMPTY_READINGS
    const _battery = Array.isArray(battery) && battery.length!==0?battery:EMPTY_READINGS
    const _ev = Array.isArray(ev) && ev.length!==0?ev:EMPTY_READINGS
    const _hp = Array.isArray(heatpump) && heatpump.length!==0?heatpump:EMPTY_READINGS

    const _g = [...EMPTY_READINGS]

    const batteryCharge = _battery.map(b => b>0?b*-1:0)
    const batteryDischarge = _battery.map(b => b<0?b*-1:0)

    const _sp = _load.map((l, i) => {
        const tGrid = l + _ev[i] + _hp[i] - batteryCharge[i]
        const tRenew = _solar[i] + batteryDischarge[i]
        const spill = tGrid - tRenew
        return spill<0?spill:0
    })

    const __series = {
        load: parseReadings(_load),
        ev: parseReadings(_ev),
        heatpump: parseReadings(_hp),
        batteryCharge: parseReadings(batteryCharge),
        batteryDischarge: parseReadings(batteryDischarge),
        grid: parseReadings(_g),
        solar: parseReadings(_solar),
        spill: parseReadings(_sp),
    }

    const _start = (MONTH_START_DAY[5]+10)*24
    const f = v => v.toFixed(1)
    // for(let i=_start; i<_start+24; ++i) {
    //     console.log(`Day ${Math.floor(i/24)+1} ${(i%24).toString().padStart(2, '0')}:00 - ` + 
    //         `Load=${f(_load[i])}, Sol=${f(_solar[i])}, Bat=${f(batteryCharge[i]*-1)}/${f(batteryDischarge[i])}, ` +
    //         `tLoad=${f(_load[i]+_ev[i]+_hp[i]-batteryCharge[i])}, tRen=${f(_solar[i]+batteryDischarge[i])}, Spill=${f(_sp[i])}`
    //     )
    // }

    return {
        community,
        setCommunity,
        ...__series, 
        getSeries(s) {
            const __s = __series[s]
            if(!Array.isArray(__s)) {
                throw new Error(`No such series "${s}"`)
            }
            return __s
        }
    }
}

const ECPlotContext = createContext(Context({}, ()=>{}))

export default ECPlotContext