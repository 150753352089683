import { useState, useMemo, useEffect, useCallback } from "react"

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import {MapContainer, TileLayer, LayersControl, FeatureGroup, CircleMarker, LayerGroup, Polyline, Marker, Popup, useMap} from 'react-leaflet'

import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

import Session from "services/session"
import NodeTreePlot from 'layouts/components/MapView/node-tree-plot'
import NodeSelectPopup from 'layouts/components/MapView/NodeSelectPopup'
import { BlueMarkerIcon, GreenMarkerIcon, RedMarkerIcon } from 'layouts/components/MapView/map-constants'

const EN_MAP_POSITION = 'energy.network.map.position'
const EN_MAP_ZOOM = 'energy.network.map.zoom'
const EN_MAP_LAYER_VISIBILITY = 'energy.network.map.layers'
const DEFAULT_POSITION = [ 52.1890, -10.1273]
const DEFAULT_ZOOM = 11

const DFLT_VISIBILITY_STATE = {
    branches: true
}

const visibilityKey = f => `f_${f._id}`

function depthFirst(node, type, attrs, matched=[]) {
    if(!node) return matched
    if(node.type===type) {
        const summary = attrs.reduce((r,c) => {
            const v = node[c]
            if(typeof(v)!=='undefined') {
                // eslint-disable-next-line no-param-reassign
                r[c] = v
            }
            return r
        }, {})
        if(Object.keys(summary).length>0) {
            matched.push(summary)
        }
    }
    const { _children=[]} = node
    for(const n of _children) {
        depthFirst(n, type, attrs, matched)
    }
    return matched
}

export default function MapView({position, zoom, network, events, selectedSites, branches, viewOnly, showTransformers}) {
    const [map, setMap] = useState(null)
    const [center, setCenter] = useState(position)
    const [zoomLevel, setZoomLevel] = useState(zoom)

    const [feeders, setFeeders] = useState([])
    const [feedersHidden, setFeedersHidden] = useState([])

    const [selectedNode, setSelectedNode] = useState(null)
    const [isNodePresent, setNodePresent] = useState(false)
    const [ popup, setPopup] = useState(null)
    const [visibilityState, setVisibilityState] = useState(null)

    useEffect(() => {
        // Set the position from session (if applicable)
        setCenter(Session.get(EN_MAP_POSITION, DEFAULT_POSITION))

        // Set the zoom from session (if applicable)
        setZoomLevel(Session.get(EN_MAP_ZOOM, DEFAULT_ZOOM))

        // Get the saved layer visibility state
        const __visibilityState = Session.get(EN_MAP_LAYER_VISIBILITY, DFLT_VISIBILITY_STATE)
        const __state = {...DFLT_VISIBILITY_STATE, ...__visibilityState}

        setVisibilityState(__state)

    }, [])

    const isFeederVisible  = useCallback(f => {
        const attr=visibilityKey(f)
        const __isVisible = visibilityState[attr]!==false
        return __isVisible
    }, [visibilityState])

    useEffect(() => {
        const __state = visibilityState!==null?visibilityState:DFLT_VISIBILITY_STATE
        Session.set(
            EN_MAP_LAYER_VISIBILITY, 
            __state)
    }, [visibilityState])

    useEffect(() => {
        if(network===null) return 
        setFeeders(network.feeders)

        return () => {
            setFeeders(null)
        }
    }, [network])

    const selectNode = useCallback((e, node) => {
        if(network===null) return
        setSelectedNode({marker: e.sourceTarget, node: {...node}})
    }, [network])

    useEffect(() => {
        if(!selectedNode || !branches || !selectedSites) return

        if(branches.length===0 || selectedSites.length===0) return

        const {_children} = selectedNode.node

        function isPresent(children=[], present=false) {
            return children.reduce((p,c) => {
                if(p) return p
                if(c.type==='dg') {
                    if(selectedSites.includes(c._id)) return true
                }
                else {
                    return isPresent(c.children)
                }
                return false
            }, present)
        }
        setNodePresent(isPresent(_children))

        return () => {
            setNodePresent(false)
        }
    }, [selectedNode, branches, selectedSites])

    const popupSummary = useMemo(() => {
        if(selectedNode===null || selectedNode.node===null) return null
        const { node } = selectedNode
        let typeLable
        let content
        switch(node.type) {
            case 'feeder':
                typeLable='Feeder'
                break
            case 'branch':
                typeLable = 'Branch'
                break
            case 'transformer': {
                typeLable='Transformer'
                // Get a summary of DG2
                const summary = depthFirst(node, "dg", ['dg', 'mec'])
                if(summary.length===0) {
                    content = (<Grid item xs={12} textAlign="right">No meters</Grid>)
                }
                else {
                    const meterTally = summary.reduce((t, {dg}) => {
                        // eslint-disable-next-line no-param-reassign
                        t[dg] = t[dg]?t[dg]+1:1
                        return t
                    }, {})
                    content = (<>
                        {[...Object.entries(meterTally)]
                            .sort(([a], [b]) => a.localeCompare(b))
                            .map(([dg, count]) => (
                                <Grid item key={`${node._id}-tally-${dg}`} xs={12} textAlign="right">
                                    <b>{dg}</b> - {count}
                                </Grid>))
                        }
                    </>)
                }
                break
            }
            default:
                return null
        }
        return (<MDBox spacing={1} mt={1.5} display="flex" justifyContent="flex-start">
            <Grid container>
                <Grid item xs={12}>
                    <b>{typeLable}</b>
                </Grid>
                {content}
            </Grid>
        </MDBox>)
    }, [selectedNode])

    const feederLayers = useMemo(() => feeders.map(f => {
        if(visibilityState===null) return null
        return (<LayersControl.Overlay 
            key={`feeder-layer-${f._id}`}
            name={f.name}
            checked={isFeederVisible(f)}>
            <LayerGroup eventHandlers={{
                add: (e) => {
                    visibilityState[visibilityKey(f)] = true
                    setVisibilityState({...visibilityState})
                },
                remove: (e) => {
                    visibilityState[visibilityKey(f)] = false
                    setVisibilityState({...visibilityState})
                }
              }} 
>
                <NodeTreePlot node={f} options={{color: 'blue'}}/>
                <Marker 
                    position={f.location} 
                    icon={BlueMarkerIcon}>
                    <Popup>{f.name}</Popup>
                </Marker>
            </LayerGroup>
        </LayersControl.Overlay>)
        }) , [feeders, visibilityState]
    )



    const branchMap = useMemo(() => {
        const __m = new Map()
    
        if(network!==null) {
            for(const {_id: feederID, name: feederName} of network.feeders) {
                const tag = `feeder=${feederName}`
                const __branches = network.branches.filter(({labels=[]}) => labels.includes(tag))
                __m.set(feederID, {
                    plot: __branches.map(b => (
                        <FeatureGroup key={`branch-plot-${b._id}`}>
                            <NodeTreePlot node={b} options={{color: 'red'}}/>
                        </FeatureGroup>
                    )),
                    markers: (<FeatureGroup key={`branch-markers-${feederID}`}>
                        {
                        __branches.filter(
                            ({_id: branchID, name: branchName, location=null}) => {
                                if(location===null) {
                                    console.warn(`[branch::${branchName}::${branchID}] - missing location`)
                                    return false
                                }
                                return true
                            }
                        ).map((b) => {
                            const {_id: branchID, name: branchName, location} = b
                            return (<Marker 
                                position={location}
                                icon={RedMarkerIcon}
                                eventHandlers={{
                                    click: e => selectNode(e, b)
                                }}
                                key={`branch-marker-${branchID}`}/>
                        )})
                        }
                    </FeatureGroup>)
                })
            }
        }
        return __m
    }, [network, branches])

    const branchLayers = useMemo(() => {
        if(visibilityState===null) return
        const __visible=[]
        for(const [feederId, {plot, markers}] of branchMap.entries() ) {
            if(isFeederVisible({_id: feederId})) {
                __visible.push(plot)
                if(zoomLevel>14) {
                    __visible.push(markers)
                }
            }
        }
        return __visible
    }, [branchMap, zoomLevel, network, visibilityState])

    const transformerMap = useMemo(() => {
        const __m = new Map()
    
        if(network!==null && showTransformers) {
            for(const {_id: feederID, name: feederName} of network.feeders) {
                const tag = `feeder=${feederName}`
                const __transformers = network.transformers.filter(({labels=[]}) => labels.includes(tag))
                __m.set(feederID, {
                    plot: [],
                    // plot: __transformers.map(t => (
                    //     <FeatureGroup key={`transformer-plot-${t._id}`}>
                    //         <NodeTreePlot node={t} options={{color: 'blue'}}/>
                    //     </FeatureGroup>
                    // )),
                    markers: (<FeatureGroup key={`transformer-markers-${feederID}`}>
                        {
                        __transformers.filter(
                            ({_id: transformerID, name: transformerName, location=null}) => {
                                if(location===null) {
                                    console.warn(`[transformer::${transformerName}::${transformerID}] - missing location`)
                                    return false
                                }
                                return true
                            }
                        ).map((b) => {
                            const {_id: transformerID, name: transformerName, location} = b
                            return (<Marker 
                                position={location}
                                icon={GreenMarkerIcon}
                                eventHandlers={{
                                    click: e => selectNode(e, b)
                                }}
                                key={`transformer-marker-${transformerID}`}/>
                        )})
                        }
                    </FeatureGroup>)
                })
            }
        }
        return __m
    }, [network, showTransformers])

    const transformerLayers = useMemo(() => {
        if(visibilityState===null) return
        const __visible=[]
        for(const [feederId, {markers}] of transformerMap.entries() ) {
            if(isFeederVisible({_id: feederId})) {
                // __visible.push(plot)
                if(zoomLevel>15) {
                    __visible.push(markers)
                }
            }
        }
        return __visible
    }, [transformerMap, zoomLevel, network, visibilityState])

    // 
    // Handle scrolling and zooming of map
    // Save the values in the session storage so that
    // continuity can be provided
    //
    const onMove = useCallback(() => {
        if(map) {
            const __pos = map.getCenter()
            setCenter(__pos)
            Session.set(EN_MAP_POSITION, __pos)
        }
    }, [map])

    useEffect(() => {
        if(map===null) return

        map.on('move', onMove)
        return () => {
            map.off('move', onMove)
        }
    }, [map, onMove])

    const onZoom = useCallback(() => {
        if(map) {
            const __zoom = map.getZoom()
            setZoomLevel(__zoom)
            Session.set(EN_MAP_ZOOM, __zoom)
        }
    }, [map])

    useEffect(() => {
        if(map===null) return

        map.on('zoom', onZoom)
        return () => {
            map.off('zoom', onZoom)
        }
    }, [map, onZoom])

    if(center===null || zoomLevel===null) return null

    return (<MapContainer
                style={{ height: "100%"}} 
                center={center} 
                zoom={zoomLevel} 
                ref={setMap}
                scrollWheelZoom>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <LayersControl position="topright">
                {/* Show the feeders  */}
                { feederLayers }
                { branchLayers }
                { transformerLayers }
            </LayersControl>
            <NodeSelectPopup selected={selectedNode} events={events} present={isNodePresent} editControls={!viewOnly}>
                {popupSummary}
            </NodeSelectPopup>
        </MapContainer>)
}

const NOOP = () => {}

MapView.defaultProps = {
    position: null,
    zoom: null,
    network: null,
    events: {
        add: NOOP,
        delete: NOOP,
        details: NOOP,
    },
    selectedSites: [],
    branches: [],
    viewOnly: false,
    showTransformers: false
}

const METER_ARRAY = PropTypes.arrayOf(
    PropTypes.shape({
        location: PropTypes.arrayOf(PropTypes.number).isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired

    } )
)

MapView.propTypes = {
    position: PropTypes.oneOf([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape({
            lat: PropTypes.number,
            lon: PropTypes.number
        })
    ]),
    zoom: PropTypes.number,
    network: PropTypes.shape({
        feeders: METER_ARRAY,
        branches: METER_ARRAY,
        transformers: METER_ARRAY
    }),
    events: PropTypes.shape({
        add: PropTypes.func,
        delete: PropTypes.func,
        details: PropTypes.func
    }),
    selectedSites: PropTypes.arrayOf(PropTypes.string),
    branches: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired
    })),
    viewOnly: PropTypes.bool,
    showTransformers: PropTypes.bool
}
