import axios from "axios";
import ServiceRegistry from "services/Registry"
// import ErrorListener from "./ErrorListener"

const HTTP_STATUS_CODES = {
    // Retrieved from: https://www.restapitutorial.com/httpstatuscodes.html
    // STATUS_CODE: response_msg

    // Redundant, success responses;
    200: '',
    201: '',
    202: '',
    204: '',
    DEFAULT_SUCCESS: 'Success',

    // Client errors;
    400: 'Bad request',
    401: 'Unauthorized access, invalid permissions',
    403: 'Forbidden, invalid permissions',
    404: 'Not found, invalid routing',
    408: 'Request timeout',
    409: 'Duplicate entry',
    DEFAULT_CLIENT_ERROR: 'Client error, please try again with the correct credentials',

    // Server errors;
    500: 'Internal server error',
    501: 'Service not currently implemented',
502: 'Service currently unavailable, try again later',
    503: 'Service currently unavailable, try again later',
    504: 'Gateway timeout, try again later',
    DEFAULT_SERVER_ERROR: 'Server error, please try again later. ',

    // Default message to display when given status code is not specified above;
    DEFAULT_ERROR: ' Error occurred, please try again later'
};

export class RESTError extends Error {
    constructor(status, message, detail) {
        super(message)
        this.status = status
        this.detail = detail
    }

    get errorClass() {
        return `__err${this.status}`
    }
}

function getErrorResponse(statusCode = null, statusText = '') {
    /**
     * Retrieves the appropriate error response based on the status data given.
     *
     * @param {Number}      statusCode      The status code of the response.
     * @param {String}      statusText      Associated API error message with the status.
     *
     * @return {String}     Concatenated status' error message with description of status code.
     */

    if (statusCode === null || statusCode === undefined) {
            return HTTP_STATUS_CODES.DEFAULT_ERROR;
    };

    const statusResponse = HTTP_STATUS_CODES[statusCode]

    if (typeof statusResponse === 'string') {
            return `${statusResponse} - ${statusText}`
    };

    if (statusCode < 500) {
            return `${HTTP_STATUS_CODES.DEFAULT_CLIENT_ERROR} - ${statusText}`
    } 
    if (statusCode < 600) {
            return `${HTTP_STATUS_CODES.DEFAULT_SERVER_ERROR} - ${statusText}`
    }

    return `${HTTP_STATUS_CODES.DEFAULT_ERROR} - ${statusText}`
};


function Request(service) {
    const $this = {}

    // console.log(`Request::${service}`)

    const __service = ServiceRegistry.get(service)

    function handleError(context, {status, statusText, data: __error={}} = {}, handle401=true) {

        switch(status) {
            case 401:
                console.error(`Call to "${context.url}" not authorised`)
                // Redirect to login page
                if(handle401) {
                    window.location.replace("/authenticate/sign-in?reason=NO_AUTH")
                    return 
                }
                return ;
            case 502:
                throw new RESTError(502, `"${__service.name}Service" not available. Please try again later`)
            default: {
                // Is it an APIError?
                const {statusCode, code, detail} = __error
                let {message=null} = __error

                if (statusCode && code && (message!==null || detail!==null)) {
                    if(code==='Validation.Error') {
                        if(detail && Array.isArray(detail.errors)) {
                            message = detail.errors.map(({code: __code, message: innerMsg, ...__e}) => {
                                let __msg
                                switch(__code) {
                                    case "Param.Required":
                                        __msg = `Parameter "${__e.param}" required`
                                        break
                                    default:
                                        console.error(`Unhandled validation error : code=${__code}, message=${message} (e = ${JSON.stringify(__e)})`)
                                        __msg = innerMsg
                                        break ;
                                }
                                return __msg
                            })
                        }
                    }
                    // console.log(`RESTError :: code=${code}`)
                    throw new RESTError(statusCode, 
                        message!==null?message:"Error", 
                        detail!==null?detail:{})
                };
            }
            // console.log(`Rejecting ${status}, text=${statusText}`)
            throw new Error(getErrorResponse(status, statusText));
        }
    }

    $this.call = async ({path, method='get', headers, params, data, handle401=true} = {}) => {
        const __axiosProps = {
            method: method.toLowerCase(),
            url: __service.createURI(path),
            headers,
            params,
            data
        }

        try {
            const __response = await axios(__axiosProps)
            return __response.data
        }
        catch(e) {
            // console.log('Err : ', e)
            const {response} = e
            if (response === undefined || response === null) {
                throw(e);
            };
            handleError(__axiosProps, response, handle401)
        }
    }

    
    $this.get = async (path, query) => $this.call({ path, method: 'get', params: query})

    $this.put = async (path, data, query) => $this.call({ path, method: 'put', data, params: query})

    return $this
}

export default Request