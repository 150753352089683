import { useState, useMemo } from "react"

import PropTypes from "prop-types";

import {FeatureGroup, Polyline} from 'react-leaflet'
import { NodePropType } from 'layouts/components/MapView/map-constants'

export default function NodeTreePlot({node, options}) {
    const [lines, setLines] = useState([])
    
    useState(() => {
        const recursiveLine = (__node, __lines=[]) => {
            __lines.push({
                _id: __node._id,
                line: [
                    __node,
                    ...__node.children
                ].map(({location: _location}) => _location)
            })
            for(const __cn of __node.children) {
                if(__cn.type===node.type) {
                    return recursiveLine(__cn, __lines)
                }
            }
            return __lines
        }

        setLines(recursiveLine(node))
    }, [])

    return (<FeatureGroup>
        {
            lines.map(({_id, line}) => (
                <Polyline key={`polyline_${_id}`} pathOptions={options} positions={line}/>
            ))
        }
    </FeatureGroup>)
}

NodeTreePlot.propTypes = {
    node : NodePropType.isRequired,
    options: PropTypes.shape({
        color: PropTypes.string.isRequired
    })
}
NodeTreePlot.defaultProps = {
    options: {}
}
