function Service(name, base=`/api/${name}`) {
    const $this = {
        name
    }

    $this.createURI = (path='') => `${base}/${path}`

    return $this
}

function Registry() {
    const __registry = new Map() 
    __registry.set('user', Service('user'))
    __registry.set('organisation', Service('organisation'))
    __registry.set('site', Service('site'))
    __registry.set('meter', Service('meter'))
    __registry.set('energy-community', Service('energy-community', '/api/ec'))
    __registry.set('optimisation', Service('optimisation', '/api/renew-opt'))

    const $this = {}

    $this.get = (s) => {
        const __svc = __registry.get(s) 
        if(!__svc) {
            throw new Error(`No such service "${s}"`)
        }
        else {
            return __svc
        }
    }
    return $this
}

export default Registry()