/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Avatar, Card, CardActionArea, CardActions, CardHeader, CardContent, Menu, MenuItem,
        Divider, Grid, Tooltip, IconButton, Typography
 } from '@mui/material';
import Icon from "@mui/material/Icon";
import MoreVertIcon from '@mui/icons-material/MoreVert';


import Service from "services/energy-community"
import {scaleWattHours} from "services/scale-series"
import LoadingBackdrop from "components/LoadingBackdrop";
import QuickEdit from "layouts/energy-community/edit/QuickEdit"

const NOOP = () => {}
const NOT_SET_TEMP = { value: null, units: 'kWh'}
const NOT_SET = { value: null, units: 'kW'}
const LH = { lineHeight: 1}


const COMMUNITY_PROP = PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    load: PropTypes.number,
    solar: PropTypes.number,
    sites: PropTypes.arrayOf(PropTypes.shape)
  })

const OPTS = {
    maximumFractionDigits: 1
}

const SX = {
    backgroundColor: "white",
    borderRightColor : "white", 
    borderRightWidth: "3px", 
    borderBottomColor : "white", 
    borderBottomWidth: "3px", 
}

// Get the locale
const [locale] = navigator.languages && navigator.languages.length>0?
    navigator.languages:
    [navigator.language || "en-GB"]
const formatter = Intl.NumberFormat(locale, OPTS)

function EnergySummary({label, value, units, precision}) {
    // const [formatedValue, setFormatedValue] = useState('--kWh')
    const [formatedValue, setFormatedValue] = useState('--')

    useEffect(() => {
        if(value===null) return

        // setFormatedValue(`${formatter.format(value.data)}${value.unitsTemporal}`)
        setFormatedValue(formatter.format(value))

        return () => {
            setFormatedValue('--')
        }
    }, [value])

    return (<>
        <Grid item sx={LH} xs={5}>
            <Typography fontWeight="bold" variant="caption">{label}</Typography>
        </Grid>
        <Grid item sx={LH} xs={7}>
            <Typography variant="caption" align="right">{formatedValue} {units}</Typography>
        </Grid>
    </>
    )
}

EnergySummary.defaultProps = {
    value: null,
    units: "kWh",
    precision: 1
}

EnergySummary.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    units: PropTypes.string,
    precision: PropTypes.number

}

const MENUITEM_STYLE = {
    sx: {justifyContent: "flex-end"}
}

const total = (a=[]) => Array.isArray(a) && a.length>0?a.reduce((t,c) => t+c, 0):null

function EnergyCommunityCard({ color, onSelect, selected: __selected, community: __c }) {
    // const [formatter, setFormatter] = useState(null)
    const [community, setCommunity] = useState(__c)
    const [editing, setEditing] = useState(null)
    const [name, setName] = useState(null)
    const [selected, setSelected] = useState(false)
    const [mouseover, setMouseOver] = useState(false)
    const [sx, setSx] = useState({...SX})
    const [anchorEl, setAnchorEl] = useState(null)
    const [running, setRunning] = useState(null)

    const navigate = useNavigate()

    const loadCommunity = useCallback(async () => {
        // Load the full record with readings
        try {
            const instance = await Service.get(__c._id, { readings: ['load', 'ev', 'heatpump'], full: true})
            setCommunity(instance)
        }
        catch(err) {
            console.log(err)
        }
    }, [community])

    useEffect(() => {
        loadCommunity()
    }, [])

    useEffect(() => {
        const {name: _name="", load: _load=[], solar: _solar=null, report: _report} = community
        setName(_name)
        // setLoad(_load)
        // setSolar(_solar)
        // setSelected(__selected && __selected._id===community._id)

        // setReport({..._report})


    }, [community])

    const sustainabilityScore = useMemo(() => {
        const {report: _report} = community

        const _score = (_report && typeof(_report.score)==='number')?
            _report.score.toFixed(1):
            '--'

        return (<>
            <Grid item xs={6}>
                <Typography variant="body2" fontWeight="bold">Sustainability:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">{_score}%</Typography>
            </Grid>
    
        </>)
    }, [community])    

    const annualLoad = useMemo(() => {
        let _annualLoad = ['--', 'kWh']
        const { load: _load, ev=[], heatpump=[] } = community
        if(Array.isArray(_load) && _load.length>0) {
            const scaled = scaleWattHours(total(_load) + total(ev) + total(heatpump))
            _annualLoad = [scaled.data, scaled.unitsTemporal]
        }

        return (<>
            <Grid item xs={6}>
                <Typography variant="body2" fontWeight="bold">Annual Load:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">{_annualLoad[0]} {_annualLoad[1]}</Typography>
            </Grid>
        </>)
    }, [community])

    const MEC = useMemo(() => {
        const { mec='--' } = community

        const _mec = Number.isNaN(mec)?
            [mec, 'kW']:
            (() => {
                const scaled = scaleWattHours(mec)
                return [scaled.data, scaled.unitsEnergy]
            })()

        return (<>
            <Grid item xs={6}>
                <Typography variant="body2" fontWeight="bold">MEC:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">{_mec[0]} {_mec[1]}</Typography>
            </Grid>
        </>)
    }, [community])

    const solarReport = useMemo(() => {
        const { report: _report=null, solar: readings=null} = community
        let size = [null, 'kW']
        if(_report && _report.solar) {
            const {size: _size=null} = _report.solar
            if(_size!==null) {
                const scaled = scaleWattHours(_size, {maxPrecision: 1})
                size = [scaled.data, scaled.unitsEnergy]
            }
        }
        let annual = [null, 'kWh']
        if(readings && Array.isArray(readings) && readings.length>0) {
            const scaled = scaleWattHours(total(readings), {maxPrecision: 1})
            annual = [scaled.data, scaled.unitsTemporal]
        }
        return (<Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold" color="primary.main">Solar</Typography>
            </Grid>
            <EnergySummary label="Size" value={size[0]} units={size[1]}/>
            <EnergySummary label="Annual" value={annual[0]} units={annual[1]}/>
        </Grid>)
    }, [community])

    const batteryReport = useMemo(() => {
        const { report: _report=null, battery: readings=null} = community
        let size = [null, 'kW']
        let min = [null, 'kW']
        if(_report && _report.battery) {
            const {size: _size=null, minCapacity=null} = _report.battery
            if(_size!==null) {
                const scaled = scaleWattHours(_size, {maxPrecision: 1})
                size = [scaled.data, scaled.unitsEnergy]
            }
            if(minCapacity!==null) {
                const scaled = scaleWattHours(minCapacity, {maxPrecision: 1})
                min = [scaled.data, scaled.unitsEnergy]
            }
        }
        let annualCharge = [null, 'kWh']
        let annualDischarge = [null, 'kWh']
        if(readings && Array.isArray(readings) && readings.length>0) {
            const scaledCh = scaleWattHours(
                total(readings.filter(v => v>=0)), 
                {maxPrecision: 1})
            annualCharge = [scaledCh.data, scaledCh.unitsTemporal]
            const scaledDis = scaleWattHours(
                Math.abs(total(readings.filter(v => v<=0))), 
                {maxPrecision: 1})
            annualDischarge = [scaledDis.data, scaledDis.unitsTemporal]
        }
        return (<Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold" color="primary.main">Battery</Typography>
            </Grid>
            <EnergySummary label="Size" value={size[0]} units={size[1]}/>
            <EnergySummary label="Ann. Ch." value={annualCharge[0]} units={annualCharge[1]}/>
            <EnergySummary label="Ann. Dis." value={annualDischarge[0]} units={annualDischarge[1]}/>
        </Grid>)
    }, [community])

    const evSummary = useMemo(() => {
        const { evPercentage=null } = community
        // eslint-disable-next-line no-restricted-globals
        const _ev=evPercentage!==null && !isNaN(evPercentage)?+evPercentage:null
        return (<Grid container>
            <EnergySummary label="EV" value={_ev} units="%"/>
        </Grid>)
    }, [community])

    const hpSummary = useMemo(() => {
        const { hpPercentage=null } = community
        // eslint-disable-next-line no-restricted-globals
        const _hp=hpPercentage!==null && !isNaN(hpPercentage)?+hpPercentage:null
        return (<Grid container>
            <EnergySummary label="HP" value={_hp} units="%"/>
        </Grid>)
    }, [community])

    useEffect(() => {
        const _sx = {}
        if(selected) {
            _sx.borderRightColor = "#B3E5FC"
            _sx.borderBottomColor = "#B3E5FC"
        }
        setSx({...SX, ..._sx})
    }, [mouseover, selected])

    const closeMenu = useCallback(() => {
        setAnchorEl(null)
    }, [community])

    const handleView = useCallback(() => {
        navigate(`../view/${community._id}`)
    }, [community])

    const handleCalculate = async () => {
        setRunning("calculate")
        try {
            const score = await Service.optimise(community._id)
        }
        catch(err) {
            console.error(err)
        }
        setRunning(null)
        closeMenu()
    }

    const handleEdit = useCallback(() => {
        setEditing(community)
        closeMenu()
    }, [community])

    const runnerCallback = useCallback((isTimeout) => {
        if(running) {
            setRunning(null)
        }
    }, [running])

    const finishEdit = (refresh=false) => {
        setEditing(null)
        if(refresh) {
            loadCommunity()
        }
    }

    return formatter?(
    <Card 
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        sx = {{...sx}}>
        <CardHeader 
            avatar={
                <Avatar sx={{ bgcolor: 'forestgreen' }} aria-label="energy-community">
                    <Icon fontSize="medium" color="inherit">energy_savings_leaf</Icon>
                </Avatar>
            }
            title={name}
            action={
                <Tooltip title="Community actions">
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}><MoreVertIcon/></IconButton>
                </Tooltip>
            }
        />
        <CardActionArea component={Link} to={`../view/${community._id}`}>
        <CardContent>
            <Grid container>
                {MEC}
                {sustainabilityScore}
                {annualLoad}
                <Grid item xs={6}>
                {evSummary}
                </Grid>
                <Grid item xs={6}>
                {hpSummary}
                </Grid>
                <Divider/>
                <Grid item xs={6}>
                    {solarReport}
                </Grid>
                <Grid item xs={6}>
                    {batteryReport}
                </Grid>

            </Grid>
        </CardContent>
        </CardActionArea>
        <Menu id={`ec-context-menu-${community._id}`}
            open={anchorEl!==null}
            anchorEl={anchorEl}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <MenuItem onClick={handleView} {...MENUITEM_STYLE}>View</MenuItem>
            <MenuItem onClick={handleCalculate} {...MENUITEM_STYLE}>Calculate Sustainability</MenuItem>
            <MenuItem onClick={handleEdit} {...MENUITEM_STYLE}>Edit</MenuItem>
        </Menu>
        { editing && <QuickEdit community={editing} onDone={finishEdit}/>}
        <LoadingBackdrop open={running!==null} 
            onTimeout={runnerCallback} 
            timeout={2000}
            timeoutMessage="Request timed out fetching sustainability score. The process may still run to completion. Please try to view the energy community (do no re-run the optimisation request)"/>
    </Card>
  ):null;
}

// Setting default values for the props of EnergyCommunityCard
EnergyCommunityCard.defaultProps = {
  color: "success",
  onSelect: () => {},
  selected: { _id: null }
};

// Typechecking props for the EnergyCommunityCard
EnergyCommunityCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  community: COMMUNITY_PROP.isRequired,
  selected: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }),
  onSelect: PropTypes.func
};

export default EnergyCommunityCard;

