import PropTypes from "prop-types";

import { renderToStaticMarkup } from "react-dom/server";
import L from 'leaflet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import greenMarkerIcon from 'assets/images/marker-icon-green.png'
import redMarkerIcon from 'assets/images/marker-icon-red.png'
import blueMarkerIcon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import "layouts/components/MapView/map-icons.css"

const MarkerIconHTML = renderToStaticMarkup(<>
    <div className="map-icon-bg"/>
    <FontAwesomeIcon icon="fa-solid fa-location-dot" size="3x"/>
</>)

const mapIconSpec = {
    html: MarkerIconHTML,
    iconAnchor: [13,37],
    iconSize:[14,14],
    bgPos: [20, 20],
    popupAnchor: [-3, -50],
    shadowUrl: iconShadow
}

export const _BlueMarkerIcon = L.icon({
    iconUrl: blueMarkerIcon,
    iconAnchor: [12,40],
    popupAnchor: [-3, -40],
    shadowUrl: iconShadow
})

export const _GreenMarkerIcon = L.icon({
    iconUrl: greenMarkerIcon,
    iconAnchor: [12,40],
    popupAnchor: [-3, -40],
    shadowUrl: iconShadow
})

export const _RedMarkerIcon = L.icon({
    iconUrl: redMarkerIcon,
    iconAnchor: [12,40],
    popupAnchor: [-3, -40],
    shadowUrl: iconShadow
})

export const RedMarkerIcon = L.divIcon({
    ...mapIconSpec,
    className: "map-icon red",
})

export const BlueMarkerIcon = L.divIcon({
    ...mapIconSpec,
    className: "map-icon blue",
})

export const GreenMarkerIcon = L.divIcon({
    ...mapIconSpec,
    className: "map-icon green",
})

export const NodePropType = PropTypes.shape({
    _id: PropTypes.string.isRequired,
    location: PropTypes.arrayOf(PropTypes.number).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape).isRequired,
    toGeoJSON: PropTypes.func.isRequired

})
