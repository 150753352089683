import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DrawerRoot from "layouts/energy-network/EnergyNodeDetailDrawer/DrawerRoot";

function EnergyNodeDetailDrawer({drawerOpen}) {
    console.log('drawerOpen = ', drawerOpen)
    return (<DrawerRoot variant="permanent" ownerState={{drawerOpen}}>
        <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            pt={4}
            pb={0.5}
            px={3}
            >
            <MDBox>
            <MDTypography variant="h5">Material UI Configurator</MDTypography>
            <MDTypography variant="body2" color="text">
                See our dashboard options.
            </MDTypography>
            </MDBox>
        </MDBox>
    </DrawerRoot>)
}

EnergyNodeDetailDrawer.defaultProps = {
    drawerOpen: true
}

EnergyNodeDetailDrawer.propTypes = {
    drawerOpen : PropTypes.bool
}

export default EnergyNodeDetailDrawer