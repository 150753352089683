import { useState, useEffect, useCallback, useContext } from "react"

import PropTypes from "prop-types";

import { useNavigate } from 'react-router-dom'

// @mui material components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Service from "services/energy-community"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import EnergyCommunityContext from 'layouts/energy-community/components/ec-context'
import { YesNoButton } from "components/ActionableButton"

const NOOP = () => {}
const IS_NUMBER = /^\s*-?(([0-9]+)|([0-9]{0,}\.?[0-9]{1,}))\s*$/

export default function EditEnergyCommunityDetails({onChange, onDone}) {
    const { model } = useContext(EnergyCommunityContext)

    const [initialName, setInitialName] = useState("")
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(null)
    const [initalMEC, setInitialMEC] = useState(0)
    const [mec, setMEC] = useState(0)
    const [mecError, setMecError] = useState(null)
    const [hpPercentage, setHPPercentage] = useState(0)
    const [hpError, setHPError] = useState(null)
    const [evPercentage, setEVPercentage] = useState(0)
    const [evError, setEVError] = useState(null)
    const [canSave, setCansave] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if(model===null) return 
        setName(model.name)
        setInitialName(model.name)
        const {mec: _mec=0, hpPercentage: _hp, evPercentage: _ev} = model
        setMEC(_mec)
        setInitialMEC(_mec)
        setHPPercentage(_hp)
        setEVPercentage(_ev)
        return () => {
            setName("")
            setInitialName("")
            setMEC(0)
            setInitialMEC(0)
        }
    }, [model])

    const onCancel = useCallback((e) => {
        onDone()
    }, [name])

    useEffect(() => {
        // Make sure name is valid
        const checkName = async (n) => {
            if(n !== initialName) {
                const exists = await Service.exists(n)
                if(exists) {
                    console.warn(`"${n}" exists`)
                    setNameError('Name exists')
                }
            }
        }
        checkName(name)
        model.name=name
        return () => {
            setNameError(null)
        }
    }, [name, initialName])

    useEffect(() => {
        const isNumber = IS_NUMBER.test(mec)
        if(!isNumber) {
            // What's the error
            const [_mec=""] = [mec]
            if(mec.length===0) {
                setMecError("MEC value required")
            }
            else {
                setMecError("Valid numeric value required")
            }
        }
        else {
            const _mec = parseFloat(mec)
            if(_mec<0) {
                setMecError("MEC must be >= 0")
            }
        }
        model.mec = mec
        return () => {
            setMecError(null)
        }
    }, [mec, initalMEC])

    useEffect(() => {
        setCansave(
            name.length>0 &&
            nameError===null && 
            mecError===null &&
            hpError===null &&
            evError===null
        )
    }, [name, nameError, mecError, hpError, evError])

    const onNext = (e) => {
        // XXX - Do validation
        onChange()
        navigate("../page2")
    }

    useEffect(() => {
        model.evPercentage = evPercentage
        model.hpPercentage = hpPercentage
    }, [hpPercentage, evPercentage])

    const validatePercentage = (e, set=NOOP, onError=NOOP) => {
        onError(null)
        const {required=false, value} = e.target
        set(value)
        if(required && value.trim()==="") {
            return onError("Value required")
        }
        const v = +value
        if(Number.isNaN(v)) {
            return onError("Number 0-100 required")
        }
        if(v<0 || v>100) {
            return onError("Value must be 0-100")
        }
    }

    return (<>
        <Grid container spacing={3} pb="1.5em">
          <Grid item>
            <MDTypography variant="h5" >Details</MDTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
                <TextField 
                    fullWidth
                    id="ec-name"
                    label="Name"
                    placeholder="Energy community name"
                    value={name}
                    error={nameError!==null}
                    helperText={nameError && nameError}
                    onChange={(e) => setName(e.target.value)}
                    required />
            </MDBox>
            <MDBox mb={1.5}>
                <TextField 
                    id="ec-mec"
                    label="MEC"
                    placeholder="Max Export Capacity"
                    value={mec}
                    error={mecError!==null}
                    helperText={mecError && mecError}
                    onChange={(e) => setMEC(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                    }}
                    required />
            </MDBox>
            <MDBox mb={1.5}>
                <TextField 
                    id="ec-hp"
                    label="Heat Pump Penetration"
                    placeholder="Heat Pump Penetration"
                    value={hpPercentage}
                    error={hpError!==null}
                    helperText={hpError && hpError}
                    onChange={(e) => validatePercentage(e, setHPPercentage, setHPError)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    required />
            </MDBox>
            <MDBox mb={1.5}>
                <TextField 
                    id="ec-ev"
                    label="EV Penetration"
                    placeholder="EV Penetration"
                    value={evPercentage}
                    error={evError!==null}
                    helperText={evError && evError}
                    onChange={(e) => validatePercentage(e, setEVPercentage, setEVError)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    required />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5} display="flex" justifyContent="flex-end" >
                <MDBox>
                    <YesNoButton
                        prompt='Unsaved changes, really cancel?'
                        check={() => model.changed}
                        variant="outlined" 
                        color="secondary" 
                        onYes={onCancel}>
                            Cancel
                    </YesNoButton>
                </MDBox>
                <MDBox pl="0.5em" >
                    <MDButton 
                        variant="gradient" 
                        endIcon={<NavigateNextIcon/>} 
                        onClick={onNext}
                        disabled={!canSave} 
                        color="primary">
                            Next
                    </MDButton>
                </MDBox>
            </MDBox>
          </Grid>
        </Grid>
    </>)
}

EditEnergyCommunityDetails.defaultProps = {
    name: "",
    onChange: () => {},
    onDone: () => {}
}
EditEnergyCommunityDetails.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onDone: PropTypes.func
}