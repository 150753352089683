export default class ECModel {
    constructor({_id=null, mec=0, hpPercentage=0, evPercentage=0, name="", location=null, sites=[] } = {}) {
        this._id = _id
        this.name = name
        this.mec = mec
        this.hpPercentage = hpPercentage 
        this.evPercentage = evPercentage
        this.location = location
        this.sites = sites

        this.initialModel = {
            _id,
            name,
            mec: this.mec,
            hpPercentage: this.hpPercentage,
            evPercentage: this.evPercentage,
            location,
            sites
        }
    }

    get mec() {
        switch(typeof(this._mec)) {
            case 'number':
                return this._mec
            case 'string':
                // eslint-disable-next-line no-case-declarations
                const v = +this._mec
                return Number.isNaN(v)?0:v
            default:
                return v
        }
    }

    set mec(_mec=0) {
        switch(typeof(_mec)) {
            case 'number':
                this._mec = _mec
                break
            case 'string':
                // eslint-disable-next-line no-case-declarations
                const v = +_mec
                this._mec = Number.isNaN(v)?0:v
                break
            default:
                this._mec = 0
        }
    }

    get state() {
        return {
            _id: this._id,
            name: this.name,
            mec: this.mec,
            hpPercentage: this.hpPercentage,
            evPercentage: this.evPercentage,
            location: this.location,
            sites: this.sites,
        }
    }

    get changed() {
        // console.log(`[ECModel::changed] _id: , ${this.initialModel._id} -> ${this._id}`)
        // console.log(`[ECModel::changed] name: , ${this.initialModel.name} -> ${this.name}`)
        // console.log(`[ECModel::changed] mec: , ${this.initialModel.mec} -> ${this.mec}`)
        // console.log(`[ECModel::changed] location: , ${this.initialModel.location} -> ${this.location}`)
        // console.log(`[ECModel::changed] sites: , ${this.initialModel.sites} -> ${this.sites}`)

        if(this._id !== this.initialModel._id ||
            this.name !== this.initialModel.name ||
            this.mec !== this.initialModel.mec ||
            this.hpPercentage !== this.initialModel.hpPercentage ||
            this.evPercentage !== this.initialModel.evPercentage ||
            this.sites.length!==this.initialModel.sites.length) {
                return true
        }
        for(const s of this.sites) {
            if(!this.initialModel.sites.includes(s)) return true
        }
        return false
    }
}