import React, { useState, useEffect, useMemo } from 'react'

import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Session from "services/session"
import MeterService from "services/meter-service"
import EnergyCommunityContext from 'layouts/energy-community/components/ec-context'
import SiteManager from 'layouts/energy-community/components/site-manager'
import Page1 from 'layouts/energy-community/components/EditEnergyCommunityDetails'
import Page2 from 'layouts/energy-community/components/SelectSites'
import ECModel from 'layouts/energy-community/ec-model'

const EC_MODEL = 'energy.community.model.current'

export default function NewEnergyCommunity() {
    const [model, setModel] = useState(null)
    const [network, setNetwork] = useState(null)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const currentModel = Session.get(EC_MODEL)
        if(currentModel) {
            setModel(new ECModel(currentModel))
        }
        else {
            const m = new ECModel()
            Session.set(EC_MODEL, m)
            setModel(m)
        }

        // Load the energy network
        const __loadNetwork = async () => {
            const __meters = await MeterService.buildNetworkGraph()
            setNetwork(__meters)
        }
        __loadNetwork()
    }, [])

    const onChange = () => {
        Session.set(EC_MODEL, model.state)
    }

    const onDone = () => {
        Session.set(EC_MODEL)
        navigate("/energy-community")
    }

    const context = useMemo(() => {
        const siteManager = SiteManager(model, network)
        return { 
            model, 
            network, 
            siteManager
         }
    }, [model, network])

    if(model===null) return null

    return (<MDBox component="form" sx={{width: 'auto'}} py={3} noValidate autoComplete="off">
        <Grid container spacing={3}>
            <Grid item>
                <MDTypography variant="h4" >New Energy Community</MDTypography>
            </Grid>
        </Grid>
        <EnergyCommunityContext.Provider value={context}>
            <Routes>
                <Route index element={<Navigate to="./page1"/>}/>
                <Route exact path='page1' element={<Page1 onChange={onChange} onDone={onDone} />} key="ec-new-page1" />
                <Route exact path='page2' element={<Page2 onChange={onChange} onDone={onDone}/>} key="ec-new-page2" />
                <Route path="*" element={<Navigate to="./page1" />} />
            </Routes>
        </EnergyCommunityContext.Provider>
    </MDBox>)

}