import { useState, useEffect } from "react"
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle, 
    Grid, Box, Alert,
    TextField, InputAdornment, Typography } from "@mui/material"
import Service from "services/energy-community"
import MDButton from "components/MDButton";
import SaveIcon from '@mui/icons-material/Save';
import { YesNoButton } from "components/ActionableButton"

const isNumber = (v) => {
    if(Number.isNaN(+v)) {
        throw new Error("Number required")
    }
    return true
}

const inRange = (v, {min=null, max=null}={}) => {
    const _v=+v
    if(min!==null && max!==null) {
        if(_v<min || _v>max) {
            throw new Error(`Must be in range ${min} - ${max}`)
        }
        return true
    }
    if(min!==null) {
        if(_v<min) {
            throw new Error(`Must be >= ${min}`)
        }
        return true
    }
    if(max!==null) {
        if(_v>max) {
            throw new Error(`Must be <= ${max}`)
        }
    }
    return true
}

const valueRequired = (v) => {
    if(typeof(v)==='undefined' || v===null || `${v}`.trim()==="") {
        throw new Error("Value required")
    }
    return true
}

export default function QuickEdit({community, onDone}) {
    const [ec, setEC] = useState(null)
    const [mec, setMEC] = useState(0)
    const [evPercentage, setEVPercentage] = useState(0)
    const [hpPercentage, setHPPercentage] = useState(0)
    const [errors, setErrors] = useState({})
    const [canSave, setCanSave] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if(community===null || !community._id) return
        (async () => {
            try {
                const _ec = await Service.get(community._id, {full: false})
                setEC(_ec)
                setMEC(_ec.mec)
                setEVPercentage(_ec.evPercentage)
                setHPPercentage(_ec.hpPercentage)
            }
            catch(err) {
                console.error(err)
            }
        })()
        return () => setEC(null)
    }, [community])

    const isEqual = () => ec!==null &&
            ec.mec===+mec &&
            ec.hpPercentage === +hpPercentage &&
            ec.evPercentage === +evPercentage

    useEffect(() => {
        const _errors={}
        // Check MEC
        try {
            valueRequired(mec)
            isNumber(mec)
            inRange(mec, {min: 0})
        }
        catch(e) {
            _errors.mec=e.message
        }

        // Check HP
        try {
            valueRequired(hpPercentage)
            isNumber(hpPercentage)
            inRange(hpPercentage, {min: 0, max: 100})
        }
        catch(e) {
            _errors.hpPercentage=e.message
        }

        // Check EV
        try {
            valueRequired(evPercentage)
            isNumber(evPercentage)
            inRange(evPercentage, {min: 0, max: 100})
        }
        catch(e) {
            _errors.evPercentage=e.message
        }

        const valid=Object.keys(_errors).length===0
        setCanSave(valid && !isEqual())
        setErrors(_errors)
        
    }, [mec, hpPercentage, evPercentage])

    const onSave = async () => {
        try {
            await Service.save({
                _id: ec._id,
                mec: +mec,
                hpPercentage: +hpPercentage,
                evPercentage: +evPercentage
            }, true)
            onDone(true)
        }
        catch(err) {
            console.error(err)
            setError(err.message)
        }
    }

    if(ec===null) return null
    return (<Dialog
        open={ec!==null}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
            //   const email = formJson.email;
            //   handleClose();
            },
          }}>
        <DialogTitle>{ec.name}</DialogTitle>
        <DialogContent dividers>
            <Box width="100%">
                { error && <Alert variant="filled" color="error">{error}</Alert>}
            <Grid sx={{mt: 1}} container spacing={1}>
                <Grid item xs={12}>
                    <Grid container  direction="column" alignItems="center" justifyContent="right" spacing={2}>
                        <Grid item>
                            <TextField 
                                fullWidth
                                id="ec-mec"
                                label="MEC"
                                placeholder="Max Export Capacity"
                                value={mec}
                                error={errors.mec}
                                helperText={errors.mec && errors.mec}
                                onChange={(e) => setMEC(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                                }}
                                required />
                        </Grid>
                        <Grid item>
                            <TextField 
                                fullWidth
                                id="ec-hp"
                                label="Heat Pump Penetration"
                                placeholder="Heat Pump Penetration"
                                value={hpPercentage}
                                error={errors.hpPercentage}
                                helperText={errors.hpPercentage && errors.hpPercentage}
                                onChange={(e) => setHPPercentage(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                required />
                            
                        </Grid>
                        <Grid item>
                            <TextField 
                                fullWidth
                                id="ec-ev"
                                label="EV Penetration"
                                placeholder="EV Penetration"
                                value={evPercentage}
                                error={errors.evPercentage}
                                helperText={errors.evPercentage && errors.evPercentage}
                                onChange={(e) => setEVPercentage(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                required />
                        
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Box>
            <Box width="100%"
                display="flex"
                alignContent="center"
                justifyContent="right">
                    <Link to={`/energy-community/edit/${community._id}/page1`}>
                        <Typography variant="button" color="primary" fontWeight={800}>Full Edit</Typography>
                    </Link>
                    {/* <MDButton variant="text" color="primary">Full Edit</MDButton> */}
            </Box>
        </DialogContent>
        <DialogActions>
            <YesNoButton
                prompt='Unsaved changes, really cancel?'
                check={() => !isEqual()}
                variant="outlined" 
                color="secondary" 
                onYes={onDone}>
                    Cancel
            </YesNoButton>
            <MDButton variant="contained" disabled={!canSave} onClick={onSave} color="primary" startIcon={<SaveIcon/>}>Save</MDButton>
        </DialogActions>
    </Dialog>)
}
QuickEdit.propTypes = {
    community: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }),
    onDone: PropTypes.func
}
QuickEdit.defaultProps = {
    community: null,
    onDone: ()=> {}
}
