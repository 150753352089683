import { useState, useEffect, useContext, useMemo } from "react"
import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import { MONTH_LABELS, DAYS_IN_MONTH, MONTH_START_DAY, HOUR_LABELS} from "layouts/energy-community/types";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Bar } from "react-chartjs-2";
import {createPlot} from "layouts/energy-community/Plots/EnergyCommunityPlot/configs";
import Card from "@mui/material/Card";
import ECPlotContext from "../plot-context";
import FullScreenPlot from "./FullScreenPlot"

export default function PlotDay({month, day, title: _title, subtitle, series, summary}) {
    const { grid, solar, spill, getSeries } = useContext(ECPlotContext)

    const [title, setTitle] = useState(_title)
    const [plotData, setPlotData] = useState({})

    useEffect(() => {
        if(_title!=="") return
        let _d = day
        const daysInMonth=DAYS_IN_MONTH[month]
        if(_d>daysInMonth-1) {
            _d=daysInMonth-1
        }

        // Determine whether day is "st", "nd", or "th"
        let dayLabel='th'
        const dom = _d+1
        if(dom<9 || dom>19) {
            switch(dom%10) {
                case 1:
                    dayLabel = 'st'
                    break
                case 2:
                    dayLabel = 'nd'
                    break
                case 3:
                    dayLabel = 'rd'
                    break
                default:
                    dayLabel = 'th'
                    break
            }
        }
        setTitle(`${MONTH_LABELS[month][1]} ${dom}${dayLabel}`)

    }, [month, day])

    const plot = useMemo(() => {
        // Aggregate the data for the days of the month
        let _d = day
        const daysInMonth=DAYS_IN_MONTH[month]
        if(_d>daysInMonth-1) {
            _d=daysInMonth-1
        }
        // Aggregate the data for the days of the month
        const dayNdx = MONTH_START_DAY[month]+_d
        // Wrap the index for next day if on Dec 31st
        const nextDay = (dayNdx+1)%365
        // Include midnight reading for next day

        const __data = createPlot({
            labels: HOUR_LABELS,
            datasets: series.map(s => {
                const __seriesData = getSeries(s)
                return [s, [...__seriesData[dayNdx].data, __seriesData[nextDay].data[0]]]
            }),
            summary: ["load"]
        })
        setPlotData(__data)

        return (
            <Bar options={__data.options} data={__data.config}/>
        )

    }, [month, day])

    return (<Card sx={{ height: "100%" }}>
        <MDBox padding="0.2rem">
            <MDBox
                variant="gradient"
                sx= {{
                    bgcolor: grey[700]
                }}
                borderRadius="lg"
                // coloredShadow="success"
                py={2}
                pr={0.5}>
                    {plot}
            </MDBox>
            <FullScreenPlot title={title} plot={plot} data={plotData}/>
            <MDBox>
                <MDTypography variant="h6" textTransform="capitalize">{title}</MDTypography>
                <MDTypography component="div" variant="button" color="text" fontWeight="light">
                    {subtitle}
                </MDTypography>
            </MDBox>
        </MDBox>
    </Card>)
}
PlotDay.propTypes = {
    month: PropTypes.number.isRequired,
    day: PropTypes.number.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.string),
    summary: PropTypes.arrayOf(PropTypes.string)
}
PlotDay.defaultProps = {
    title: "",
    subtitle: "Hourly data",
    series: ['grid', 'solar', 'spill'],
    summary: ["load"]
}
