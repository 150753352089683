import { useState, useRef } from "react"

import PropTypes from "prop-types";

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Bar, getElementsAtEvent } from "react-chartjs-2";

import { grey } from "@mui/material/colors";

export default function FullScreenPlot({title, plot, data, onClick}) {
    const [open, setOpen] = useState(false)
    const _ref = useRef()

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = (event) => {
        const [clicked=null] = getElementsAtEvent(_ref.current, event);
        if(clicked!==null) {
            onClick(clicked.index)
        }
    }

    return (<>
        <MDTypography onClick={() => setOpen(true)} align="right"><IconButton><FullscreenIcon/></IconButton></MDTypography>
        <Dialog open={open}
            fullWidth
            maxWidth="lg"
            onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <MDBox padding="0.2rem">
                <MDBox
                    variant="gradient"
                    sx= {{
                        bgcolor: grey[700]
                    }}
                    borderRadius="lg"
                    py={2}
                    pr={0.5}>
                    <Bar ref={_ref} onClick={handleClick} options={data.options} data={data.config}/>
                </MDBox>
                </MDBox>
            </DialogContent>
        </Dialog>
    </>)
}

FullScreenPlot.propTypes = {
    title: PropTypes.string.isRequired,
    plot: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    data: PropTypes.shape({
        options: PropTypes.oneOfType([PropTypes.object]),
        config: PropTypes.oneOfType([PropTypes.object])
    }).isRequired
}
FullScreenPlot.defaultProps = {
    onClick: () => {}
}