/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useMemo } from "react"

import { Link, useLocation } from "react-router-dom"

import Session from "services/session"

// @mui material components
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import EnergyCommunityCard from "layouts/energy-community/Cards/EnergyCommunityCard";

import Service from "services/energy-community"

const EC_SELECTED_INSTANCE = 'energy.community.selected'

const fabStyle = {
    position: 'relative',
    // top: "1em",
    right: "1em",
  };

function EnergyCommunity() {
    const [energyCommunities, setEnergyCommunities] = useState([])
    const [selected, setSelected] = useState(null)
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(null)
    const {state} = useLocation()


    async function __list() {
        const communities = await Service.list()
        setEnergyCommunities(communities)

        const __selected = Session.get(EC_SELECTED_INSTANCE, null)
        if(__selected) {
            const [loaded=null] = communities.filter(({_id}) => _id===__selected)
            if(loaded) {
                // Previously selected has been loaded - select it automatically
                setSelected(loaded)
            }
            else {
                setSelected(null)
                // Previously selected not in list - clear the stored key
                Session.set(EC_SELECTED_INSTANCE)
            }
        }
    }

    useEffect(() => {
        __list()

        return () => {
            setEnergyCommunities([])
            setSelected(null)
        }
    }, [])

    useEffect(() => {
        if(state===null) return
        const { error: _e=null} = state
        setError(_e)

        return () => setError(null)
    }, [state])

    const selectEC = useCallback((ec) => {
        setSelected(ec)
        Session.set(EC_SELECTED_INSTANCE, ec._id)
    }, [energyCommunities, selected])

    const addButton = useMemo(() => (<Grid item xs={12} sx={{height: "2em"}}>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            <Fab component={Link} to="../new" color="success" aria-label="add community" sx={fabStyle}>
                <AddIcon color="white" fontSize="large"/>
            </Fab>
        </div>
    </Grid>), [energyCommunities])

    return (
            <MDBox py={3}>
                { error && <Alert variant="filled" severity='error' onClose={() => {setError(null)}}>{error}</Alert>}
                <Grid container spacing={3}>
                    {addButton}
                    {energyCommunities.length>0?energyCommunities.map(ec => (
                        <Grid key={`ec_${ec._id}`} item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <EnergyCommunityCard
                                    community={ec}
                                    color="success"
                                    selected={selected}
                                    onSelect={selectEC}
                                />
                            </MDBox>
                        </Grid>
                    )):
                    (<Grid item xs={12} md={6} lg={3}>
                        <MDTypography variant="body2" >No energy communities found</MDTypography>
                    </Grid>)}
                </Grid>
            </MDBox>
    );
}

export default EnergyCommunity;
