import React, { useState, useEffect, useMemo } from 'react'

import { Routes, Route, Navigate, useParams, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "layouts/containers/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";


import Session from "services/session"
import MeterService from "services/meter-service"
import EnergyCommunityContext from 'layouts/energy-community/components/ec-context'
import SiteManager from 'layouts/energy-community/components/site-manager'
import Page1 from 'layouts/energy-community/components/EditEnergyCommunityDetails'
import Page2 from 'layouts/energy-community/components/SelectSites'
import ECModel from 'layouts/energy-community/ec-model'

import Service from "services/energy-community"

const INITIAL_MODEL = {
    _id: null, 
    name: "",
    mec: 0,
    location: null,
    sites: []
}

const EC_MODEL = 'energy.community.model.current'
const RE_OBJECTID=/[a-f0-9]{24}/i

export default function EditEnergyCommunity() {
    const [model, setModel] = useState(null)
    const [sites, setSites] = useState(null)
    const [network, setNetwork] = useState(null)
    const [ec, setEC] =useState(null)
    const [error, setError] = useState(null)

    const location = useLocation()
    const navigate = useNavigate()

    const {id} = useParams()

    useEffect(() => {
        if(!id || !RE_OBJECTID.test(id)) {
            console.error(`Malformed ID "${id}"`)
            navigate('/energy-community')
        }

        // Clear any model
        Session.set(EC_MODEL)
        async function __load() {
            try {
                const [instance, _network] = await Promise.all([
                    Service.get(id, {readings: ["load"]}),
                    MeterService.buildNetworkGraph()])
                if(instance) {
                    setEC(instance)
                    const { sites: __sites=[]} = instance
                    setModel(new ECModel({
                        _id: instance._id,
                        name: instance.name,
                        mec: instance.mec,
                        hpPercentage: instance.hpPercentage,
                        evPercentage: instance.evPercentage,
                        location: instance.location,
                        sites: __sites.map(({_id: siteID}) => _network.getSiteMeter(siteID, {type: 'dg', dlft: {}})._id )
                    }))
                    setNetwork(_network)
                    return
                }
            }
            catch(err) {
                console.error("Error fetching energy community : ", err)
            }
            setError("Error loading energy community")
        }

        __load()

        return () => {
            setError(null)
        }

    }, [id])

    const onChange = () => {
        Session.set(EC_MODEL, model)
    }

    const onDone = () => {
        Session.set(EC_MODEL)
        navigate("/energy-community")
    }

    const context = useMemo(() => {
        if(!model || !network) return
        const siteManager = SiteManager(model, network)
        return { 
            model, 
            network, 
            siteManager
         }
    }, [model, network])

    if(model===null) return null

    return (<MDBox component="form" sx={{width: 'auto'}} py={3} noValidate autoComplete="off">
        <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
                <MDTypography variant="h4" >Edit Energy Community</MDTypography>
            </Grid>
            { error && (<Grid item lg={12} md={12} xs={12}>
                <Alert severity='error'>{error}</Alert>
            </Grid>)}
        </Grid>
        {model && network?(
        <EnergyCommunityContext.Provider value={context}>
            <Routes>
                <Route index element={<Navigate to="./page1"/>}/>
                <Route exact path='/page1' element={<Page1 onChange={onChange} onDone={onDone} />} key="ec-edit-page1" />
                <Route exact path='/page2' element={<Page2 onChange={onChange} onDone={onDone}/>} key="ec-edit-page2" />
                <Route path="*" element={<Navigate to="./page1" />} />
            </Routes>
        </EnergyCommunityContext.Provider>):null}
    </MDBox>)

}

