import {useState, useEffect, useCallback, useMemo} from 'react'

import PropTypes from "prop-types";

import {Link, useNavigate, useParams} from 'react-router-dom'

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import BackIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { EnergyCommunityType } from "layouts/energy-community/types";
import EnergyCommunityPlot from "layouts/energy-community/Plots/EnergyCommunityPlot";

import Service from "services/energy-community"
import Session from "services/session"
import { YesNoButton } from "components/ActionableButton"
import {scaleWattHours} from "services/scale-series"

import SelectCommumity from './select';
import ECPlotContext, {Context} from "./Plots/plot-context"

const EC_SELECTED_INSTANCE = 'energy.community.selected'

function FormattedValue(label, v, {
    scale=true,
    units,
    temporal=true
}={}) {
    let _val
    let _units
    if(!scale || v===null) {
        if(v===null) {
            _val='--'
        }
        else {
            _val=+v
        }
        if(typeof(units)==="string") {
            _units = units
        }
        else {
            _units=temporal?'kWh':'kW'
        }
    }
    else {
        const _scaled = scaleWattHours(+v)
        _val = _scaled.data
        if(typeof(units)==="string") {
            _units = units
        }
        else {
            _units = temporal?_scaled.unitsTemporal:_scaled.unitsEnergy

        }
    }
    return (<>
        <MDTypography variant="subtitle" fontWeight="bold">{label}: </MDTypography>
        <MDTypography sx={{pr: 1}} variant="subtitle">{_val}{_units}</MDTypography>
    </>) 
}

const aggregateSeries = s => s.reduce((t,c) => t+c, 0)

export default function ViewEnergyCommunity({onChange}) {
    const params=useParams()
    const [ecID, setEcID] = useState(params.id)
    const [error, setError] = useState(null)
    const [community, setCommunity] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {

        if(!ecID.match(/^[a-fA-F0-9]{24}$/)) {
            navigate("../dashboard", {state: {error: `Community ID=${ecID} not found`}})
        }

        // Load the full community
        async function __load() {
            let _errMsg="Not found"
            try {
                const instance = await Service.get(ecID, { readings: ['load', 'ev', 'heatpump'], full: true})
                if(instance) {
                    setCommunity(instance)
                    return
                }
            }
            catch(err) {
                console.error("Error fetching energy community : ", err)
                _errMsg = err.message
            }
            navigate("../dashboard", {state: {error: `Community ID=${ecID} - ${_errMsg}`}})
        }


        __load()

        return () => {
            setCommunity(null)
            setError(null)
        }
    }, [ecID])

    const CommunityError = useMemo(() => {
        if(error===null) return null

        return (
            <Alert severity='error'>{error}</Alert>
        )
    }, [error])

    const doEdit = useCallback(() => {
        // Edit the energy community
        if(community===null) return

        navigate(`/energy-community/edit/${community._id}`, {state: {ec: community}})
    }, [community, navigate])

    const doDelete = useCallback(() => {
        // Try to delete the energy community
        if(community===null) return

        async function __delete() {
            try {
                await Service.delete(community._id)
                // Clear the currently viewed in session
                Session.set(EC_SELECTED_INSTANCE)
                // Update the main page
                onChange(`Energy community "${community.name} deleted"`)
            }
            catch(err) {
                setError(`Error deleting - ${err.message}`)
            }
        }
        __delete()

    }, [community, navigate])

    const ecSummary = useMemo(() => {
        const c = community || {}
        const { mec=null, evPercentage='--', hpPercentage='--', report={}} = c
        const { score='--', solar={}, battery={}} = report
        const { size: sSize=null} = solar
        const { size: bSize=null} = battery
        const { load=[], ev=[], heatpump=[]} = c
        const totalLoad = aggregateSeries(load) + 
            aggregateSeries(ev) + 
            aggregateSeries(heatpump) 

        return (<>
            {FormattedValue("Ann. Load", totalLoad, { temporal: true })}
            {FormattedValue("MEC", mec, { temporal: false })}
            {FormattedValue("Solar", sSize, { temporal: false})}
            {FormattedValue("Batt.", bSize, {temporal: true})}
            {FormattedValue("EV", evPercentage, { scale: false, units: "%" })}
            {FormattedValue("HP", hpPercentage, { scale: false, units: "%" })}
            {FormattedValue("Sust.", score, { scale: false, units: "%" })}
        </>)
        // return (<MDTypography variant="subtitle">MEC: {formatValue(mec, false)}, EV: {evPercentage}%, HP: {hpPercentage}%, Solar: {formatValue(sSize)}, Batt.: {formatValue(bSize)}, Score={score}%</MDTypography>)
    }, [community])

    const Community = useMemo(() => {
        if(community===null) return null

        return (<Grid container justifyContent="flex-end" sx={{pb: 1}}>
            <Grid item lg={8} xs={12} md={6}>
                <MDTypography variant="h4">{community.name}</MDTypography>
                {ecSummary}
                <MDBox mb={1.5} display="flex">
                   <Link to="../dashboard"><MDButton variant='text' 
                        color='primary' 
                        size="large" 
                        onClick={doEdit}
                        sx={{mr: 1}}
                        startIcon={<BackIcon/>}>Back</MDButton></Link>
                </MDBox>
                
            </Grid>
            <Grid item xs={12} md={6} lg={4} alignContent="right">
                <SelectCommumity current={community} onChange={setEcID}/>                
                <MDBox mb={1.5} display="flex" justifyContent="flex-end">
                    <YesNoButton 
                        prompt={`Delete energy community "${community.name}"?`}
                        variant='outlined' 
                        color='secondary' 
                        size='small' 
                        onYes={doDelete}
                        sx={{mr: 1}}
                        startIcon={<DeleteIcon/>}>Delete</YesNoButton>
                    <MDButton variant='contained' 
                        color='primary' 
                        size='small' 
                        onClick={doEdit}
                        sx={{mr: 1}}
                        startIcon={<EditIcon/>}>Edit</MDButton>
                </MDBox>
            </Grid>
            <Grid item xs={12}>
                <ECPlotContext.Provider value={Context(community, setCommunity)}>
                    <EnergyCommunityPlot/>
                </ECPlotContext.Provider>
            </Grid>
        </Grid>)
    }, [community])

    return community!==null?(<MDBox mt={4.5}>
        {CommunityError}
        {Community}
    </MDBox>
    ) : null
}

ViewEnergyCommunity.propTypes = {
    // ec: EnergyCommunityType(),
    onChange: PropTypes.func
}

ViewEnergyCommunity.defaultProps = {
    // ec: null,
    onChange: () => {}
}
