const  KILOWATT_SCALE_PRECISION = [
    [1, 2, 'kWh', 'kW'], // 10 - Kilowatts
    [1, 1, 'kWh', 'kW'], // 100
    [1, 0, 'kWh', 'kW'], // 1000
    [1000, 2, 'MWh', 'MW'], // 1000 - MegaWatts
    [1000, 1, 'MWh', 'MW'], // 10000
    [1000, 0, 'MWh', 'MW'], // 100000
    [1000000, 2, 'GWh', 'GW'], // 1000000 - GigaWatts
    [1000000, 1, 'GWh', 'GW'], // 10000000
    [1000000, 0, 'GWh', 'GW']  // 1000000000
]

export const DEFAULT_WATT_HOUR_SCALER = {
    transform: v=>v,
    divisor: 1,
    precision: 0,
    units: 'Wh'
}

export const minMax = (series) => series.reduce(([_min, _max], v) => ([
    v<_min?v:_min,
    v>_max?v:_max
]), [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER])

export function scaleWattHours(series, {maxPrecision=null} = {}) {
    if(typeof(series)!=='number' && (!Array.isArray(series) || series.length===0))return null

    let min
    let max
    let isScaler
    if(typeof(series)==='number') {
        min = series
        max = series
        isScaler=true
    }
    else {
        [min,max] = minMax(series)
        isScaler=false
    }

    // default to last value
    let __selected = KILOWATT_SCALE_PRECISION[KILOWATT_SCALE_PRECISION.length-1]

    // loop through KILOWATT_SCALE_PRECISION to find a set that reduces the value below 1000
    for(let i=0, l=KILOWATT_SCALE_PRECISION.length; i<l; ++i) {
        const __scaler = KILOWATT_SCALE_PRECISION[i]
        if(max/__scaler[0] < 1000) {
            __selected = __scaler
            break
        }
    }

    // Scale the series
    const [divisor, __precision, unitsTemporal, unitsEnergy] = __selected
    const precision=maxPrecision!==null?maxPrecision:__precision
    const __scale = v => parseFloat((v/divisor).toFixed(precision))
    function scale(__data) {
        if(Array.isArray(__data)) {
            return __data.map(__scale)
        }
        return __scale(__data)
    }
    const __scaled = isScaler?__scale(series):series.map(__scale)

    return {
        label: `Energy (${unitsTemporal})`,
        unitsTemporal,
        unitsEnergy,
        minValue: __scale(min),
        maxValue: __scale(max),
        scale,
        toPrecision: v => parseFloat(v).toFixed(precision),
        data: __scaled
    }

}

// Build series
const AXIS_PARAMS = [
    [10000, 2500, 45],
    [5000, 1000, 40],
    [2500, 500, 40],
    [1000, 250, 40],
    [500, 100, 30],
    [250, 50, 30],
    [100, 25, 40],
    [50, 10, 30],
    [20, 4, 30],
    [10, 2, 30],
    [5, 1, 30],
    [null, 0.5, 30]
]

export function calculateAxisParams(max, curr={max: 0}) {
    if(max<=curr.max) return curr ;
    for(let i=0, l=AXIS_PARAMS.length; i<l; ++i) {
        const [__m, interval, nameGap] = AXIS_PARAMS[i]
        if(__m===null || max>__m) {
            const __max = Math.ceil(max/interval)*interval
            return { max: __max, interval, nameGap }
        }
    }
}
