export default function SiteManager(model, network) {
    const $this = {}

    $this.sites = [{
        _id: "1",
        name: "Site 1"
    }, {
        _id: "2",
        name: "Site 2"
    }]

    $this.add = (node) => {
        $this.sites = [...$this.sites, {node}]
        console.log(`[onAdd] - ${node.name} `, $this.sites)
    }

    $this.details = (node) => {
        console.log(`[onDetails] - ${node.name}`)
    }

    $this.delete = (node) => {
        console.log(`[onDelete] - ${node.name}`)
    }

    $this.sites = [{
        _id: "1",
        name: "Site 1"
    }, {
        _id: "2",
        name: "Site 2"
    }]

    return $this
}