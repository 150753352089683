import { useState, useEffect, useCallback } from "react"

import Session from "services/session"

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/containers/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";

import Service from "services/meter-service"
import NodeListDrawer from "layouts/energy-network/NodeListDrawer"
import EnergyNodeDetailDrawer from "layouts/energy-network/EnergyNodeDetailDrawer";
import MapView from 'layouts/components/MapView'

import LeafletMap from "./Map";

function EnergyNetwork() {
    const [network, setNetwork] = useState(null)
    const [feeders, setFeeders] = useState(null)
    const [branches, setBranches] = useState(null)
    const [transformers, setTransformers] = useState(null)

    useEffect(() => {
        const __load = async () => {
            const __meters = await Service.buildNetworkGraph()
            setNetwork(__meters)
        }
        __load()
    }, [])

    useEffect(() => {
        if(network===null) return
        setFeeders(network.feeders)
        setBranches(network.branches)
        setTransformers(network.transformers)

        return () => {
            setFeeders(null)
            setBranches(null)
        }
    }, [network])

    const params = {
        feeders,
        branches,
        transformers
    }

    return (<MDBox py={3} sx={{height: "80vh", minHeight: "450px"}}>
        <MapView network={network} viewOnly showTransformers />
        {/* <LeafletMap {...params}/> */}
    </MDBox>);

}

export default EnergyNetwork