import Request from "services/http"

function EnergyCommunityService() {
    const $this = {}
    const __request = Request("energy-community")
    const __requestMeter = Request("meter")
    const __requestOpt=Request("optimisation")

    $this.list = async () => {
        try {
            return await __request.get()
        }
        catch(err) {
            console.error("Error getting energy communities : ", err)
            return []
        }
    }

    $this.get = async (_id, { readings=[], full=true} = {}) => {
        const __ec = await __request.get(_id, {readings, full})
        const { mec=0, hpPercentage=0, evPercentage=0, ...rest } = __ec
        return { ...rest, mec, hpPercentage, evPercentage }
    }   

    $this.getAggregateMeterReadings = async (type, ec) => {
        if(!ec || !Array.isArray(ec.sites) || ec.sites.length<1) return null;

        try {
            return await __requestMeter.put(`${type}/readings/site`,
                {
                    site_id: ec.sites.map(({_id}) => _id),
                    correlation_id: ec._id
                },
                {
                    summary: true
                })
        }
        catch(err) {
            // if(err.code)
            console.error("Error getting meter summary", err, err.code)
        }
        return null
    }

    $this.exists = async (name="") => {
        if(name===null || name.length===0) return false
        try {
            const { exists } = await __request.get('exists', {
                name
            })
            return exists
        }
        catch(err) {
            console.error("Error calling 'exists' API : ", err)
            return false
        }

    }

    $this.save = async (ec=null, partial=false)=> {
        if(!ec) throw new Error("Energy community required")
        const {_id, mec=0, hpPercentage=0, evPercentage=0, name="", sites=[]} = ec
        try {
            const _rec = { name, mec, hpPercentage, evPercentage, sites}
            if(!_id) {
                // Create new
                await __request.put('', _rec)
            }
            else {
                // Update existing
                const query = {}
                let upd
                if(partial) {
                    query.partial = partial
                    upd = {
                        mec: _rec.mec,
                        hpPercentage: _rec.hpPercentage,
                        evPercentage: _rec.evPercentage
                    }
                }
                else {
                    upd=_rec
                }
                await __request.put(`${_id}`, upd, query)
            }
        }
        catch(e) {
            console.error("[energy-community] Error saving : ", e)
            throw new Error(e.message)
        }
    }

    $this.delete = async (id) => {
        if(!id) return

        try {
            await __request.call({
                path: `${id}`,
                method: 'delete'
            })
        }
        catch(e) {
            console.error(e.message)
            throw new Error(e.message)
        }
    }

    $this.optimise = async (id) => {
        try {
            return await __requestOpt.get(`optimise/${id}`)
        }
        catch(err) {
            throw new Error(err.message)
        }
    }

    return $this

}

export default EnergyCommunityService()