import Request from "services/http"

export function findTopBranch(__n) { 
    if(!__n) return null 
    const {__topBranch=null, parent } = __n
    if(__topBranch) return __topBranch
    if(parent.type==='feeder' ) return __n 
    const __node = findTopBranch(parent) 
    // Cache it for performance
    // __n.__topBranch = __node // eslint-disable-line
    return __node
}

export function findByType(n, type) {
    if(!n) return []

    const __scan = (__n, list=[]) => {
        const {type: __type, _children: children} = __n
        if(__type===type) list.push(__n)
        for(const c of children) {
            __scan(c, list)
        }
        return list
    }

    return __scan(n)
}

function NetworkGraph(meters) {
    const $this = {
        feeder: []
    }

    class NetworkNode {
        constructor(raw, type=null) {
            const {_id, name, description, metadata={}, parent_id: parentId = null, site=null, weight=0} = raw
            const {location, mic, mec, dg} = metadata
            // const {raw} = nodeMap.get(_id)
            const l = location?
                [location.coordinates[1], location.coordinates[0]]:
                null
            this._id = _id ;
            this.type = type ;
            this.name = name ;
            this.description = description ;
            this.parentId = parentId ;
            this.parent = null
            this._children = []
            this.mic = mic ;
            this.mec = mec ;
            this.dg = dg
            this.site=site
            this.location = l ;
            this.weight = weight ;
            this.raw = raw
            this.labels = [`type=${type}`]
    
        }

        get children() {
            return this._children
        }

        set children(values=[]) {
            for(const c of values) c.parent = this
            this._children = values
        }

        addLabel(l) {
            this.labels.push(l)
        }

        toGeoJSON() {
            const recursiveLine = (__type, __node, lines=[]) => {
                lines.push([
                    __node,
                    ...__node.children
                ].map(({location: _location}) => _location))
                for(const __cn of __node.children) {
                    if(__cn.type===__type) {
                        return recursiveLine(__type, __cn, lines)
                    }
                }
                return lines
            }
            switch(this.type) {
                case 'feeder':
                case 'branch':
                    return recursiveLine(this.type, this)
                default:
                    return []
            }
        }

    }

    const checkType = (types, t) => types.includes(t)?t:null

    const workingSet = meters.map(m => {
            const _type=checkType(m.meter_type, 'substation') || 
                checkType(m.meter_type, 'feeder') ||
                checkType(m.meter_type, 'branch') ||
                checkType(m.meter_type, 'transformer') ||
                checkType(m.meter_type, 'dg')
            return _type!==null?
                new NetworkNode(m, _type):
                null
        })
        .filter(m => m!==null)

    const lookup = new Map(workingSet.map(n => ([n._id, n])))
    // console.log('Working set : ', workingSet)

    const nodeRegistry = {}
    const siteMeterMap = new Map()

    const buildTree = (n) => {
        // Look for nodes with n as a parent
        const _node = n
        const {_id: parentId, type, site: _site=null} = _node

        if(_site!==null) {
            let siteRec = siteMeterMap.get(_site._id)
            if(!siteRec) {
                siteRec={}
                siteMeterMap.set(_site._id, siteRec)
            }
            if(siteRec[type]) {
                console.error(`Meter of type "${type}" already set for site "${_site.name}" (ID=${_site._id}) : ` +
                    `meter 1 : { _id: "${siteRec[type]._id}}", name: "${siteRec[type].name}"}, ` +
                    `meter 2 : { _id: "${_node._id}}", name: "${_node.name}"} `)
            }
            else {
                siteRec[type] = _node
            }
        }

        const _children = workingSet
            .filter(c => c.parentId===parentId)
            .sort(({weight: a}, {weight: b}) => a-b)
        // console.log(`buildTree(${_node.name}) - id=${parentId}, children=`, _children)

        // Add 'n' to the node registry if it's a branch with children
        if(_children.length>0) {
            if(!Array.isArray(nodeRegistry[type])) {
                nodeRegistry[type] = []
            }
            nodeRegistry[type].push(_node)
        }

        _node.children = _children
        // Work down the tree
        for(const c of _children) {
            buildTree(c)
        }
    }

    const _substations = workingSet.filter(({type}) => type==='substation')
    for(const s of _substations) {
        buildTree(s)
        // Iterate over the feeders and tag them
        for(const __feeder of s.children) {
            const __tag = `feeder=${__feeder.name}`
            // Depth first search
            const __label = (__node) => {
                __node.addLabel(__tag)
                if(__node.children.length===0) return
                for(const __c of __node.children) __label(__c)
            }

            __label(__feeder)
        }
    }

    $this.get = (__id) => lookup.get(__id)

    $this.feeders = _substations[0].children

    $this.branches = Array.isArray(nodeRegistry.branch)?nodeRegistry.branch:[]

    $this.transformers = Array.isArray(nodeRegistry.transformer)?nodeRegistry.transformer:[]

    $this.getSiteMeter = (siteID, { type='dg', dflt=null} = {}) => {
        const _siteRec = siteMeterMap.get(siteID)
        if(!_siteRec) return dflt
        return _siteRec[type]?_siteRec[type]:dflt
    }



    return $this
}

function MeterService() {
    const $this = {}
    const __request = Request("meter")


    $this.buildNetworkGraph = async() => {
        try {
            const _meters = await __request.get('', { class: 'network', sites: true})
            return NetworkGraph(_meters)
        }
        catch(err) {
            // if(err.code)
            console.error("Error getting meter summary", err, err.code)
        }
        return null
    }

    return $this
}

export default MeterService()