import { useState, useMemo, useEffect, useCallback } from "react"
import {MapContainer, TileLayer, LayersControl, CircleMarker, LayerGroup, Polyline, Marker, Popup, useMap} from 'react-leaflet'
import PropTypes from "prop-types";

import Session from "services/session"

import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

import {
    useMaterialUIController,
    setOpenConfigurator
  } from "context";

import AwesomeMarkers from "leaflet.awesome-markers";
// import Configurator from "examples/Configurator";
import NodeListDrawer from "layouts/energy-network/NodeListDrawer"

// import icon from 'leaflet/dist/images/marker-icon.png';
import greenMarkerIcon from 'assets/images/marker-icon-green.png'
import redMarkerIcon from 'assets/images/marker-icon-red.png'
import blueMarkerIcon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const EN_MAP_POSITION = 'energy.network.map.position'
const EN_MAP_ZOOM = 'energy.network.map.zoom'
const EN_MAP_LAYER_VISIBILITY = 'energy.network.map.layers'

const DEFAULT_POSITION = [52.1479, -10.2990]

const blueMarker = L.icon({
    iconUrl: blueMarkerIcon,
    iconAnchor: [12,40],
    popupAnchor: [-3, -40],
    shadowUrl: iconShadow
})

const greenMarker = L.icon({
    iconUrl: greenMarkerIcon,
    iconAnchor: [12,40],
    popupAnchor: [-3, -40],
    shadowUrl: iconShadow
})

const redMarker = L.icon({
    iconUrl: redMarkerIcon,
    iconAnchor: [12,40],
    popupAnchor: [-3, -40],
    shadowUrl: iconShadow
})

const DFLT_PROPS = {
    onClick: () => {}
}

const DFLT_VISIBILITY_STATE = {
    branches: true
}

const NODE_TYPE = PropTypes.shape({
        _id: PropTypes.string.isRequired,
        location: PropTypes.arrayOf(PropTypes.number).isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape).isRequired,
        toGeoJSON: PropTypes.func.isRequired

    })

const NODE_PROP_TYPES = {
    node: NODE_TYPE.isRequired,
    onClick: PropTypes.func
}

const DG_UNKNOWN = '???'

function TransformerNode({node, onClick}) {
    const { location, name, children } = node
    const gglURL = `http://maps.google.com/maps?q=description+(${name})+%40${location.join(',')}`
    // Build a map of the DG profile behind the transformer
    const dgMap = new Map()
    for(const n of node.children) {
        const {dg=DG_UNKNOWN} = n
        if(dg===DG_UNKNOWN) {
            console.warn(`No DG declared for "${n.name}"/"${n.description}" (ID="${n._id}")`)
        }
        const rec = dgMap.get(dg)
        if(rec) {
            rec.count += 1
        }
        else {
            dgMap.set(dg, { count: 1})
        }
    }
    const sortedDgs = [...dgMap.entries()].sort(([a], [b]) => a.localeCompare(b))
    return (
        <Marker 
            position={location} 
            icon={greenMarker}
            eventHandlers={{
                click: e => console.log(`Click ${name}`),
                mouseover: e => {
                    e.target.openPopup();
                },
                mouseout: e => {
                    e.target.closePopup();
                }
            }}>

        <Popup>
            <strong>Transformer</strong> : {name}<br/>
            Location : <a target="_blank" rel="noreferrer" href={gglURL}>{location.join(',')}</a>
            <div>
                <strong>{children.length} meter{children.length!==1?'s':''}:</strong>
            </div>
            <ul style={{paddingLeft: "1.5em"}}>
                    {
                        sortedDgs.map(([label, {count: dgCount}]) => (
                            <li key={`dg_${node._id}_${label}`}><strong>{label}</strong> : {dgCount}</li>
                        ))
                    }
            </ul>
        </Popup>
    </Marker>
    )
}
TransformerNode.defaultProps = DFLT_PROPS
TransformerNode.propTypes = NODE_PROP_TYPES
    
function NodeTreePlot({node, options}) {
    const recursiveLine = (__node, lines=[]) => {
        lines.push({
            _id: __node._id,
            line: [
                __node,
                ...__node.children
            ].map(({location: _location}) => _location)
        })
        for(const __cn of __node.children) {
            if(__cn.type===node.type) {
                return recursiveLine(__cn, lines)
            }
        }
        return lines
    }
    const __lines = recursiveLine(node)
    return (<>
        {
            __lines.map(({_id, line}) => (
                <Polyline key={`pl_${_id}`} pathOptions={options} positions={line}/>
            ))
        }
    </>)
}

NodeTreePlot.propTypes = {
    node : NODE_TYPE.isRequired,
    options: PropTypes.shape({
        color: PropTypes.string.isRequired
    })
}
NodeTreePlot.defaultProps = {
    options: {}
}

function LeafletMap({position, zoom, feeders, branches, transformers}) {
    const [center, setCenter] = useState(position)
    const [zoomLevel, setZoomLevel] = useState(zoom)
    const [map, setMap] = useState(null)
    const [branchMarkersVisible, showBranchMarkers] = useState(true)
    const [selectedNode, setSelectedNode] = useState(null)

    const [controller, dispatch] = useMaterialUIController()
    const [feederModel, setFeederModel] = useState([])
    const [visibilityState, setVisibilityState] = useState(null)

    const selectNode = (node) => {
        setSelectedNode(node)
        setOpenConfigurator(dispatch, true)
    }

    useEffect(() => {
        // Get the saved layer visibility state
        const __visibilityState = Session.get(EN_MAP_LAYER_VISIBILITY, DFLT_VISIBILITY_STATE)
        const __state = new Map()
        for(const [k, v] of Object.entries(__visibilityState)) {
            __state.set(k, v!==false)
        }
        setVisibilityState(__state)

        showBranchMarkers(__visibilityState.branches!==false)

        return () => {
            Session.set(EN_MAP_LAYER_VISIBILITY, Object.fromEntries(__state.entries()))
        }
    }, [])

    const isFeederVisible  = useCallback(f => {
        const attr=`f_${f._id}`
        return visibilityState.get(attr)!==false
    }, [visibilityState])

    const updateVisibilityState = useCallback((branchState=null, feederState=null) => {
        if(visibilityState===null) return

        if(branchState!==null) {
            visibilityState.set('branches', branchState===true)
        }
        if(feederState!==null) {
            const {_id, visible: isVisible } = feederState
            visibilityState.set(`f_${_id}`, isVisible)
        }
    }, [visibilityState])

    useEffect(() => {
        updateVisibilityState(branchMarkersVisible)
    }, [branchMarkersVisible])

    const onMove = useCallback(() => {
        if(map) {
            const __pos = map.getCenter()
            setCenter(__pos)
            Session.set(EN_MAP_POSITION, __pos)
        }
    }, [map])

    const onZoom = useCallback(() => {
        if(map) {
            const __zoom = map.getZoom()
            setZoomLevel(__zoom)
            Session.set(EN_MAP_ZOOM, __zoom)
        }
    }, [map])

    useEffect(() => {
        if(map===null) return

        map.on('move', onMove)
        return () => {
            map.off('move', onMove)
        }
    }, [map, onMove])

    useEffect(() => {
        if(map===null) return

        map.on('zoom', onZoom)
        return () => {
            map.off('zoom', onZoom)
        }
    }, [map, onZoom])

    useEffect(() => {
        let _p
        if(!position) {
            _p=Session.get(EN_MAP_POSITION, DEFAULT_POSITION)
        }
        else {
            _p = position
        }
        if(!Array.isArray(_p)) {
            if(typeof(_p.lat)==='number' && typeof(_p.lng)==='number') {
                _p = [_p.lat, _p.lng]
            }
            else {
                _p = DEFAULT_POSITION
            }
        }
        const _z = Session.get(EN_MAP_ZOOM, 13)
        setCenter(_p)
        setZoomLevel(_z)
    }, [position, zoom] )

    useEffect(() => {
        if(visibilityState===null || !Array.isArray(feeders) || feeders.length===0) return

        setFeederModel(feeders.map(f => {
            const {location=[0,0]} = f 
            const __branches = []
            const __transformers = []

            // Do a depth first search for branches
            const df = currentFeeder => {
                if(currentFeeder.children.length===0) return
                for(const cn of currentFeeder.children) {
                    switch(cn.type) {
                        case 'branch':
                            __branches.push(cn)
                            df(cn)
                            break
                        case 'transformer':
                            __transformers.push(cn)
                            break
                        case 'feeder':
                            df(cn)
                            break
                        default: 
                            return
                    }
                }
            }
            df(f)

            return {
                _id: f._id, 
                name: f.name,
                feeder: f,
                visible: isFeederVisible(f),
                plot: (<NodeTreePlot node={f} options={{color: 'blue'}}/>),
                marker: (<Marker position={f.location} icon={blueMarker}>
                    <Popup>
                        Feeder : {f.name}<br/>
                        Location : <a href={`http://maps.google.com/maps?q=description+(${f.name})+%40${location.join(',')}`}
                                        target="_blank" rel="noreferrer" >{location.join(',')}</a>
                    </Popup>
                </Marker>),
                branches: (<>
                    { 
                    __branches.map(b => (<div key={`feeder-${f._id}-branch-${b._id}-plot`}>
                        <NodeTreePlot key={`brplot-${b._id}`} node={b} options={{color: 'red'}}/>
                    </div>))}
                    </>),
                branchMarkers: (<>
                    { 
                    __branches.map(b => (<div key={`feeder-${f._id}-branch-${b._id}-marker`}>
                        <Marker 
                            position={b.location} 
                            icon={redMarker}
                            eventHandlers={{
                                click: e => console.log(`Click ${b.name}`),
                                mouseover: e => {
                                    e.target.openPopup();
                                },
                                mouseout: e => {
                                    e.target.closePopup();
                                }
                            }}>
                            <Popup>
                                Branch : {b.name}<br/>
                                Location : <a target="_blank" rel="noreferrer" 
                                    href={`http://maps.google.com/maps?q=description+(${b.name})+%40${b.location.join(',')}`}>{b.location.join(',')}</a>
                            </Popup>
                        </Marker>
                        </div>))}
                    </>),
                transformers: __transformers.map(node => (
                    <TransformerNode
                        key={`feeder-${f._id}-transformer-${node._id}`} 
                        node={node}
                        onClick={e => selectNode(node)}/>
                ))
            }
        }))
        
        return () => {
            setFeederModel([])
        }
    }, [feeders, visibilityState])

    const showFeeder = useCallback(f => {
        for(const fm of feederModel) {
            if(fm._id === f._id) {
                if(!fm.visible) {
                    fm.visible = true
                    updateVisibilityState(null, fm)
                }
                return
            }
        }
    }, [feederModel])

    const hideFeeder = useCallback(f => {
        for(const fm of feederModel) {
            if(fm._id === f._id) {
                if(fm.visible) {
                    fm.visible = false
                    updateVisibilityState(null, fm)
                }
                return
            }
        }
    }, [feederModel])

    if(visibilityState===null || center===null || zoomLevel===null) return null

    return (<div style={{height: "100%"}}>
        <MapContainer 
                style={{ height: "100%"}} 
                center={center} 
                zoom={zoomLevel} 
                ref={setMap}
                scrollWheelZoom>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <LayersControl position="topright">
                {
                    feederModel.map(f => (
                        <LayersControl.Overlay name={f.name} key={`f_${f._id}`} checked={f.visible}>
                            <LayerGroup eventHandlers={{
                                add: (e) => showFeeder(f),
                                remove: (e) => hideFeeder(f)}}>
                                { f.plot }
                                { f.marker }
                                { branchMarkersVisible && f.branches }
                                { branchMarkersVisible && zoomLevel>14?f.branchMarkers:null}
                                { branchMarkersVisible && zoomLevel>15?f.transformers:null}
                            </LayerGroup>
                        </LayersControl.Overlay>))
                }
                <LayersControl.Overlay key="zzzzzz" name="Branches" checked={branchMarkersVisible}>
                    <LayerGroup eventHandlers={{
                        add: (e) => showBranchMarkers(true),
                        remove: (e) => showBranchMarkers(false)
                    }}/>
                </LayersControl.Overlay>

            </LayersControl>
        </MapContainer>
        <NodeListDrawer node={selectedNode}/>
    </div>)
}

LeafletMap.defaultProps = {
    position: null,
    zoom: null,
    feeders: [],
    branches: [],
    transformers: []
}

const METER_ARRAY = PropTypes.arrayOf(
    PropTypes.shape({
        location: PropTypes.arrayOf(PropTypes.number).isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired

    } )
)

LeafletMap.propTypes = {
    position: PropTypes.oneOf([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape({
            lat: PropTypes.number,
            lon: PropTypes.number
        })
    ]),
    zoom: PropTypes.number,
    feeders: METER_ARRAY,
    branches: METER_ARRAY,
    transformers: METER_ARRAY
}

export default LeafletMap