import { useState, useEffect, useCallback, useMemo, useContext } from "react"
import { useNavigate, Link } from 'react-router-dom'

import PropTypes from "prop-types";

import 'leaflet/dist/leaflet.css';

// @mui material components
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import SaveIcon from '@mui/icons-material/Save';
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore';

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import EnergyCommunityContext from 'layouts/energy-community/components/ec-context'
import MapView from 'layouts/components/MapView'
import CommunityTree from 'layouts/energy-community/components/CommunityTree'

import {findTopBranch, findByType} from "services/meter-service"
import Service from "services/energy-community"
import { YesNoButton } from "components/ActionableButton"

const TYPES = {
    'branch': "Branch"
}

function PopupContent({node}) {
    const [title, setTitle] = useState("")

    useEffect(() => {
        if(node===null) return 
        const _type=TYPES[node.type]?TYPES[node.type]:"<unknown>"

        setTitle(`${_type} : ${node.name}`)
    }, [node])
    
    if(!node) return null
    const { model } = useContext(EnergyCommunityContext)

    return (
        <MDBox component="form" sx={{width: 'auto'}} py={3} noValidate autoComplete="off">
        <Grid container spacing={3} pb="1em">
          <Grid item>
            <MDTypography variant="h5" >{title}</MDTypography>
          </Grid>
        </Grid>

        </MDBox>
    )
}
PopupContent.propTypes = {
    node: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string
    }).isRequired
}


export default function SelectSites({onChange, onDone}) {
    const [canSave, setCanSave] = useState(false)

    const { model, network} = useContext(EnergyCommunityContext)

    const [branches, setBranches] = useState([])
    const [sites, setSites] = useState(null)
    const [error, setError] =useState(null)
    const [mecNotSet, setMECNotSet] = useState(false)

    const navigate = useNavigate()

    const { name } = model

    useEffect(() => {
        if(!name || name.length===0) {
            navigate('../page1')
        }
        const {mec=0} = model
        if((typeof(mec) === 'string' && mec.trim()==="") ||
            Number.isNaN(+mec) ||
            +mec===0) {
                setMECNotSet(true)
            }
    }, [])

    const onCancel = useCallback((e) => {
        onDone()
    }, [])

    useEffect(() => {
        if(network===null || !Array.isArray(model.sites)) return

        // Hydrate the sites
        const __branchMap = new Map()

        for(const sID of model.sites) {
            const branchNode = findTopBranch(network.get(sID))
            if(branchNode && !__branchMap.has(branchNode._id)) {
                __branchMap.set(branchNode._id, branchNode)
            }
        }
        const __branches = [...__branchMap.values()]
            .sort(({name: a, name: b}) => a.localeCompare(b))
        setBranches(__branches)
        setSites(model.sites)
    }, [network])

    useEffect(() => {
        if(sites===null) return
        model.sites=sites
        setCanSave(sites.length>0 && model.changed)
        onChange()

        return () => setCanSave(false)
    }, [sites])

    const onPrev = (e) => {
        // XXX - Do validation
        navigate("../page1")
    }

    const onSave = (e) => {
        e.preventDefault()
        const __save = async() => {
            try {
                // Map the site meters to sites
                
                const __sites = sites.map(meterID => {
                    const m = network.get(meterID)
                    if(!m) {
                        throw new Error(`No meter ID="${meterID}"`)
                    }
                    if(!m.site || !m.site._id) {
                        throw new Error(`No site associated with meter "${m.name}" (ID="${m._id}")`)
                    }
                    return m.site._id
                })
                await Service.save({_id: model._id, 
                    name: model.name, 
                    mec: model.mec, 
                    hpPercentage: +model.hpPercentage,
                    evPercentage: +model.evPercentage,
                    sites: __sites})
                onDone()
            }
            catch(err) {
                setError(err.message)
            }
        }
        setError(null)
        __save()    
    }

    const events = useMemo(() => {
        // const isMember = (node) => {
        //     const {_id} = node
        //     for(const {_id: cId} of branches) {
        //         if(_id===cId) return true
        //     }
        //     return false
        // }

        const flatten = (node, list=[]) => {
            if(node.type==='dg') {
                list.push(node._id)
                return
            }
            if(Array.isArray(node._children) && node._children.length>0) {
                for(const c of node._children) {
                    flatten(c, list)
                }
            }
            return list
        }

        return {
            add: (toAdd) => {
                if(!Array.isArray(toAdd)) {
                    const node = toAdd
                    // Find the top node of the tree
                    let topLevelBranch = node
                    while(topLevelBranch.parent!==null && topLevelBranch.parent.type!=='feeder') {
                        topLevelBranch = topLevelBranch.parent
                    }
                    // Check if the top level is already added
                    let isMember=false
                    for(const b of branches) {
                        if(b._id === topLevelBranch._id) {
                            isMember=true
                            break
                        }
                    }
                    // Add main branch to the list if main branch is not already there
                    if(!isMember) {
                        setBranches([...branches, topLevelBranch])
                    }
                    const currentSites = [...sites]
                    const selectedSites = flatten(node)
                    for(const s of selectedSites) {
                        if(!currentSites.includes(s)) {
                            currentSites.push(s)
                        }
                    }
                    setSites(currentSites)
                }
                else {
                    const __add = toAdd.filter(a => !sites.includes(a))
                    if(__add.length>0) {
                        setSites([...model.sites, ...__add])
                    }
                }
            },
        
            details: (node) => {
                console.log(`[onDetails] - ${node.name}`)
            },
        
            delete: (toDelete) => {
                function removeSites(siteList) {
                    setSites(sites.filter(s => !siteList.includes(s)))
                }

                if(!Array.isArray(toDelete)) {
                    const {_id: deleteId} = toDelete
                    const __branches = branches.filter(({_id: branchID}) => deleteId!==branchID)
                    const __sites = findByType(toDelete, 'dg')
                    setBranches(__branches)
                    removeSites(__sites.map(({_id: siteMeterID}) => siteMeterID))
                }
                else {
                    // Delete nodes
                    removeSites(toDelete)
                }
            }
        }
    }, [branches, sites])


    return(<>
        <Grid container spacing={1.5} pb="1.5em">
          <Grid item>
            <MDTypography variant="h5" >{name} - Sites</MDTypography>
          </Grid>
        </Grid>
        { mecNotSet && (
            <Grid container mb={1.5}>
                <Grid item lg={9} md={12} xs={12}>
                    <Alert severity="warning">MEC for energy community is 0. <Link to='page1'>Click here</Link> to set</Alert>
                </Grid>
            </Grid>
        )}
        { error && (
        <Grid container mb={1.5}>
          <Grid item lg={9} md={12} xs={12}>
              <Alert severity="error">Error saving : {error}</Alert>
          </Grid>
        </Grid>)}
        <Grid container spacing={1.5}>
            <Grid item lg={9} md={12} xs={12}>
                <MDBox sx={{height: "60vh", minHeight: "450px"}}>
                    <MapView 
                        network={network} 
                        events={events}
                        branches={branches}
                        selectedSites={sites}
                        />
                </MDBox>
                <MDBox my={1.5} display="flex" justifyContent="flex-end" >
                    <MDBox>
                        <YesNoButton
                            prompt='Unsaved changes, really cancel?'
                            check={() => model.changed}
                            variant="outlined" 
                            color="secondary" 
                            onYes={onCancel}>
                                Cancel
                        </YesNoButton>
                    </MDBox>
                    <MDBox pl="0.5em" >
                        <MDButton 
                            variant="gradient" 
                            startIcon={<NavigatePrevIcon/>} 
                            onClick={onPrev}
                            color="secondary">
                                Back
                        </MDButton>
                    </MDBox>
                    <MDBox pl="0.5em" >
                        <MDButton 
                            variant="gradient" 
                            startIcon={<SaveIcon/>} 
                            onClick={onSave}
                            disabled={!(canSave && model.changed)} 
                            color="primary">
                                Save
                        </MDButton>
                    </MDBox>
                </MDBox>
            </Grid>
            <Grid item lg={3} md={12} xs={12}>
                <CommunityTree tree={branches} 
                    selectedSites={sites}
                    events={events}/>
            </Grid>
        </Grid>

     </>)
}

SelectSites.defaultProps = {
    onChange: () => {},
    onDone: () => {},
    network: null
}
SelectSites.propTypes = {
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    network: PropTypes.shape()
}