import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Service from "services/energy-community"
import MDTypography from "components/MDTypography";

export default function SelectCommumity({current, onChange}) {
    const [ communities, setComunities] = useState([])
    const [ currentID, setCurrentID] = useState("")

    useEffect(() => {
        async function load() {
            const __c = await Service.list()
            setComunities(__c.map(({_id: value, name: label}) => ({value, label})))
        }
        load()

        return () => setCurrentID("")
    }, [])

    useEffect(() => {
        setCurrentID(current._id)
    }, [current])

    const handleChange = useCallback((e) => {
        const _id = e.target.value
        if(_id!==currentID) {
            onChange(_id)
        }
    }, [current])

    if(communities.length===0) return null

    return (<FormControl fullWidth>
        <MDTypography variant="body2" align="right" sx={{fontWeight: 600}}>Select Energy Community</MDTypography>
        <Select 
            sx={{minHeight: "3rem"}}
            labelId="lbl-select-community"
            id="select-community"
            onChange={handleChange}
            value={currentID}>
            {
                communities.map(({value, label}) => (<MenuItem value={value} key={`sel-ec-${value}`}>
                    <MDTypography align="right" variant="body2">{label}</MDTypography>
                </MenuItem> ))
            }
    </Select>
    </FormControl>)
}

SelectCommumity.propTypes = {
    current: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }).isRequired,
    onChange: PropTypes.func
}

SelectCommumity.defaultProps = {
    onChange: () => {}
}

